import { ButtonProps } from "antd";
import { Container } from "./styles";

interface IProps extends ButtonProps {
  label: string;
}

export function Button({ label, ...rest }: IProps) {
  return (
    <Container danger color="#c30830" {...rest}>
      {label}
    </Container>
  );
}
