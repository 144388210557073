import { api } from "../services/api";

import {
  createContext,
  useContext,
  useState,
  ReactNode,
} from "react";
import { useLogged } from "./userLogin";

interface IProps {
  children: ReactNode;
}

interface INotificationsContext {
  fetchNotifications: (
    page: number,
    order: string,
    orderBy: string
  ) => Promise<void>;
  updateNotification: (
    notification_id: string,
    read: boolean
  ) => Promise<void>;
  notifications: INotifications[];
  loading: boolean;
  error: boolean;
  total: number;
}

interface INotifications {
  id: string;
  message: string;
  read: boolean;
  user_id: string;
  created_at: string;
}

export const NotificationsContext = createContext<INotificationsContext>({} as INotificationsContext);

export const NotificationStorage = ({ children }: IProps) => {
  const [notifications, setNotifications] = useState<INotifications[]>([]);
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState(0);
  const { token } = useLogged();


  async function fetchNotifications(
    page: number,
    order: string,
    orderBy: string
  ) {
    setLoading(true);
    setError(null);

    const url = `/notifications/all?page=${page}&items=7&columnOrder=${orderBy}&order=${order}`;

    api
      .get(url,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      ).then((response) => {
        setNotifications(response.data.data.notifications);
        setTotal(response.data.data.totalNotifications);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function updateNotification(
    notification_id: string,
    read: boolean,
  ) {
    setLoading(true);
    setError(null);

    const updatedNotifications = notifications.map((notification) => {
      if (notification.id === notification_id) {
        return { ...notification, read: read };
      }
      return notification;
    });

    const url = `notifications/${notification_id}`;

    api
      .put(url,
        {
          read: read
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });

    setNotifications(updatedNotifications);
  }

  return (
    <NotificationsContext.Provider
      value={{
        fetchNotifications,
        notifications,
        updateNotification,
        loading,
        error,
        total,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export function useNotifications() {
  return useContext(NotificationsContext);
}