import styled from "styled-components";
import Sider from "antd/lib/layout/Sider";

export const Sidebar = styled(Sider)`
  border-right: 1px solid rgba(0, 0, 0, 0.06);

  span.anticon svg {
    font-size: ${({ collapsed }) => (collapsed ? "18px" : "14px")};
  }

  .-title-content,
  .menu-item-title {
    display: ${({ collapsed }) => (collapsed ? "none" : "initial")};
  }

  .ant-menu-title-content {
    margin-left: ${({ collapsed }) => (collapsed ? "-24px" : "initial")};
  }

  .ant-layout-sider-children {
    background-color: #ffffff !important;
  }
`;

interface ILogoContainerProps {
  collapsed: boolean;
  children: any;
}
export const LogoContainer = styled.div<ILogoContainerProps>`
  display: flex;
  justify-content: center;

  .logo {
    margin-top: 10px;
    width: ${({ collapsed }) => (collapsed ? "4rem" : "8rem")};
    /* height: 4rem; */
    cursor: pointer;

    @media (max-width: 800px) {
      width: 4rem;
      /* height: 2rem; */
    }
  }
`;
