import { AxiosError, AxiosResponse } from "axios";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { api } from "../services/api";
import { useLogged } from "./userLogin";
import moment from "moment";

interface IProps {
  children: ReactNode;
}

interface IAllPhases {
  phase_title: string;
}

interface IPhase {
  description: string;
  finish_date?: null;
  finished: number;
  id: string;
  index: number;
  plan_finish_date: string;
  plan_init_date: string;
  project_id: string;
  result_finish_date: string;
  result_init_date: string;
  title: string;
}
export interface IProjects {
  id: string;
  title: string;
  description: string;
  date_init: string;
  plan_finish_date: string;
  result_finish_date: string;
  current_phase: IPhase;
  template_id: string;
  status_id: number;
  image: string;
}

export interface IProjectsReport {
  id: string;
  title: string;
  description: string;
  date_init: string;
  plan_finish_date: string;
  result_finish_date: string;
  current_phase: IPhase;
  template_id: string;
  project_status_id: number;
  task_status_id: number;
  image: string;
}

interface ICreateProjectDTO {
  id: string;
  title: string;
  description: string;
  date_init: Date;
  image?: string;
  template_id: string;
  status_id?: number;
}

interface IProjectContext {
  projects: IProjects[];
  projectsReport: IProjectsReport[];
  loading: boolean;
  loadingGantt: boolean;
  error: any | null;
  total?: number;
  totalPages: number;
  isNewModalVisible: boolean;
  handleModalVisible: (visible: boolean) => void;
  createProjects: ({ }: ICreateProjectDTO) => Promise<any>;
  uploadImage: any; //apenas teste
  fetchCurrentPhases: () => Promise<void>;
  fetchAllPhases: () => Promise<void>;
  deleteProjects: (id: string) => Promise<AxiosResponse | AxiosError>;
  updateProjects: (id: string, { }: ICreateProjectDTO) => Promise<AxiosResponse | AxiosError>;
  fetchProjects: (
    page: number,
    order: string,
    orderBy: string,
    search?: string,
    phasesId?: string[],
  ) => Promise<void>;
  fetchAllProjects: () => Promise<void>;
  handleUsersProjects: () => Promise<void>;
  fetchProjectsReports: (
    page: number,
    order: string,
    orderBy: string,
    search?: string,
    searchUsersProjects?: string,
    dates?: any,
    termSearch?: string | null,
    projectStatusSearch?: any,
    taskStatusSearch?: any,
    phasesId?: string[]
  ) => Promise<void>;
  handleReportExportArqv: (
    page?: number,
    order?: string,
    orderBy?: string,
    search?: string,
    searchUsersProjects?: string,
    dates?: any,
    termSearch?: string | null,
    statusSearchProjects?: any,
    filterPhase?: string,
  ) => Promise<void>;
  currentPhases: string[];
  allPhases: IAllPhases[];
  allProjects: any[];
  currentTask?: string;
  fetchProjectsGantt: (
    search?: any,
    searchPhase?: any,
  ) => void;
  projectsGantt: any;
  allUsersProjects: any[];
}

export const ProjectContext = createContext<IProjectContext>({} as IProjectContext);

export const ProjectsStorage = ({ children }: IProps) => {
  const [total, setTotal] = useState(0);
  const [projects, setProjects] = useState<IProjects[]>([]);
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isNewModalVisible, setINewModalVisible] = useState(false);
  const [currentPhases, setCurrentPhases] = useState<string[]>([]);
  const [allPhases, setAllPhases] = useState<IAllPhases[]>([]);
  const [allProjects, setAllProjects] = useState<any[]>([])
  const { token } = useLogged();
  const [currentTask, setCurrentTask] = useState();
  const [projectsGantt, setProjectsGantt] = useState<any[]>([]);
  const [projectsReport, setProjectsReport] = useState<IProjectsReport[]>([]);
  const [allUsersProjects, setAllUsersProjects] = useState<any[]>([]);

  const [loadingGantt, setLoadingGantt] = useState<boolean>(false)

  const requestOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  function toArrayBuffer(buf: any) {
    let ab = new ArrayBuffer(buf.length);
    let view = new Uint8Array(ab);
    for (let i = 0; i < buf.length; ++i) {
      view[i] = buf[i];
    }
    return ab;
  }

  async function handleModalVisible(visible: boolean) {
    setINewModalVisible(visible);
  }

  async function fetchProjects(
    page: number,
    order: string,
    orderBy: string,
    search?: string,
    phasesId?: string[],
    currentReportPlanFinishDate?: string
  ) {
    setLoading(true);
    setError(null);

    let url = `/projects/list?page=${page}&items=12&columnOrder=${orderBy}&order=${order}`;

    if (search) {
      url = `${url}&search=${search}`;
    }

    if (currentReportPlanFinishDate) {
    }

    if (phasesId?.length) {
      url = `${url}&phasesId=${phasesId?.join()}`;
    }

    api
      .get(url,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setProjects(response.data.data.projects);
        setTotal(response.data.data.projectsNumber);
        setTotalPages(response.data.data.totalPages);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function fetchAllProjects() {
    setLoading(true);
    setError(null);
    setLoadingGantt(true)

    let url = `/projects/all`;

    api
      .get(url,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        let list: any[] = []
        response.data.data.projects.map((e: any) => {
          list.push({title: e.title, id: e.id})
        })
        setAllProjects(list)
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function handleUsersProjects() {
    setLoading(true);
    setError(null);

    let url = `/all/users`;

    api
      .get(url,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        let list: any[] = []
        response.data.data.projects.map((e: any) => {
          list.push(e.title)
        });
        setAllUsersProjects(list)
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function fetchProjectsReports(
    page: number,
    order: string,
    orderBy: string,
    search?: string,
    searchUsersProjects?: string,
    dates?: any,
    termSearch?: string | null,
    projectStatusSearch?: any,
    taskStatusSearch?: any,
    phasesId?: string[],
  ) {
    setLoading(true);
    setError(null);

    let newOrderBy
    if(orderBy == 'title' || orderBy == '' ){
      newOrderBy = `projects.${orderBy}`
    } else {
      newOrderBy = orderBy
    }

    let url = `/projects/report?page=${page}&items=7&columnOrder=${newOrderBy}&order=${order}`;

    if (search) {
      url = `${url}&search=${search}`;
    }

    if (searchUsersProjects) {
      url = `${url}&search_user_projects=${searchUsersProjects}`
    }

    if (phasesId?.length) {
      url = `${url}&phase_id=${phasesId?.join()}`;
    }

    if (dates && dates.plan_init_date) {
      url = `${url}&plan_init_date=${dates.plan_init_date}`;
    }

    if (dates && dates.plan_finish_date) {
      url = `${url}&plan_finish_date=${dates.plan_finish_date}`;
    }

    if (dates && dates.result_init_date) {
      url = `${url}&result_init_date=${dates.result_init_date}`;
    }

    if (dates && dates.result_finish_date) {
      url = `${url}&result_finish_date=${dates.result_finish_date}`;
    }

    if (projectStatusSearch){
      url = `${url}&project_status=${projectStatusSearch}`
    }

    if (taskStatusSearch){
      url = `${url}&task_status=${taskStatusSearch}`
    }

    if(termSearch) {
      url = `${url}&term_search=${termSearch}`
    }

    api
      .get(url,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setProjectsReport(response.data.data.projects);
        setTotal(response.data.data.projectsNumber);
        setTotalPages(response.data.data.totalPages);
      })
      .catch((err) => {
        console.error('err', err)
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function handleReportExportArqv(
    page?: number,
    order?: string,
    orderBy?: string,
    search?: string,
    searchUsersProjects?: string,
    dates?: any,
    termSearch?: string | null,
    projectStatusSearch?: any,
    taskStatusSearch?: any,
  ) {
    try {

      let newOrderBy
      if(orderBy == 'title' || orderBy == '' ){
        newOrderBy = `projects.${orderBy}`
      } else {
        newOrderBy = orderBy
      }
      let url = `/projects/report-xls?page=${page}&items=7&columnOrder=${newOrderBy}&order=${order}`;

      if (search) {
        url = `${url}&search=${search}`;
      }

      if (searchUsersProjects) {
        url = `${url}&searchUsersProjects=${searchUsersProjects}`
      }

      if (dates && dates.plan_init_date) {
        url = `${url}&plan_init_date=${dates.plan_init_date}`;
      }

      if (dates && dates.plan_finish_date) {
        url = `${url}&plan_finish_date=${dates.plan_finish_date}`;
      }

      if (dates && dates.result_init_date) {
        url = `${url}&result_init_date=${dates.result_init_date}`;
      }

      if (dates && dates.result_finish_date) {
        url = `${url}&result_finish_date=${dates.result_finish_date}`;
      }

      if (projectStatusSearch){
        url = `${url}&project_status=${projectStatusSearch.join(',')}`;
      }

      if (taskStatusSearch){
        url = `${url}&task_status=${taskStatusSearch.join(',')}`
      }

      if(termSearch) {
        url = `${url}&term_search=${termSearch}`
      }

      const response = await api.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      let blob = new Blob([toArrayBuffer(response.data.data.dataArqv.data)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'report.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchProjectsGantt(
    search?: string,
    searchPhase?: string,
  ) {
    setLoading(true);
    setLoadingGantt(true)

    let url = '/projects/reports/highcharts?page=1&items=7&columnOrder=title&order=asc';


    if (search) {
      url = `${url}&search=${search}`;
    }

    if (searchPhase) {
      url += `&searchPhase=${searchPhase}`;
    }

    api
      .get(url,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (response) => {
        const reportData: any[] = [];

        //ordenador por phases
        const manipulator = response.data.data.projects;

        const manipulatorPhases = manipulator.map((e: any)=> {
          e.phases.sort((a: any, b: any) => {
            if(a.index > b.index) return 1;
            if(a.index < b.index) return -1;
            return 0;
          })
          return e;
        });

        //ordenador de tasks
        const manipulatorTask = manipulatorPhases.map((e: any) => {
           e.phases.map((y: any) => {
            y.tasks.sort((a: any, b: any) => {
              if(moment(a.result_finish_date).toDate().getTime() > moment(b.result_finish_date).toDate().getTime() )return 1;
              if(moment(a.result_finish_date).toDate().getTime() < moment(b.result_finish_date).toDate().getTime())return -1;
              return 0;
            })
            return y;
           })
           return e;
        });

        manipulatorTask.forEach((objeto_projeto: any) => {
          let name     = objeto_projeto.title;
          let id       = objeto_projeto.id;
          let start = moment(objeto_projeto.date_init).toDate().getTime();
          let end   = moment(objeto_projeto.result_finish_date).toDate().getTime();

          reportData.push({
            name,
            id,
            start,
            end,
            date_start: objeto_projeto.date_init,
            date_end: objeto_projeto.result_finish_date,
          });

          objeto_projeto.phases.forEach((objeto_phases: any) => {
            let name     = objeto_phases.title;
            let id       = objeto_phases.id;
            let start = moment(objeto_phases.result_init_date || objeto_phases.plan_init_date).toDate().getTime();
            let end   = moment(objeto_phases.result_finish_date || objeto_phases.plan_finish_date).toDate().getTime();
            let parent   = objeto_projeto.id;
            //let dependency = objeto_projeto.id

            reportData.push({ name,
              id,
              start,
              end,
              date_start: objeto_phases.result_init_date || objeto_phases.plan_init_date,
              date_end: objeto_phases.result_finish_date || objeto_phases.plan_finish_date,
              parent,
              //dependency
            });

            objeto_phases.tasks.forEach((objeto_tasks: any) => {
              let name     = objeto_tasks.title;
              let id       = objeto_tasks.id;
              let start = moment(objeto_tasks.result_init_date).toDate().getTime();
              let end   = moment(objeto_tasks.result_finish_date).toDate().getTime();
              let parent   = objeto_phases.id;
              //let dependency = objeto_phases.id

              reportData.push({ name,
                id,
                start,
                end,
                date_start: objeto_tasks.result_init_date,
                date_end: objeto_tasks.result_finish_date,
                parent,
                //dependency
              });
            });
          });
        });

        //definindo linhas para iniciarem fechadas
        const ReportDataAllClosed = reportData.map((e) => {
          e.collapsed = true;
          return e;
        });

        setProjectsGantt(ReportDataAllClosed);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
        setLoadingGantt(false)
      });
  }

  async function fetchCurrentPhases() {
    setLoading(true);
    setError(null);

    let url = `/projects/getPhases`;

    api
      .get(url,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setCurrentPhases(response.data.data.phases);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function fetchAllPhases() {
    setLoading(true);
    setError(null);

    let url = `/projects/getAllPhases`;

    api
      .get(url,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setAllPhases(response.data.data.phases);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function deleteProjects(id: string): Promise<AxiosResponse | AxiosError> {
    let response: AxiosResponse | AxiosError;

    try {
      setLoading(true);
      setError(null);

      response = await api.delete(`/projects/${id}`, requestOptions);

      await fetchProjects(1, 'asc', 'title');
    } catch (err) {
      response = err as AxiosError;
      setError(err);
    } finally {
      setLoading(false);
    }

    return response;
  }

  async function updateProjects(
    id: any,
    { title, description, date_init, template_id, status_id, image }: ICreateProjectDTO
  ): Promise<AxiosResponse | AxiosError> {
    let response: AxiosResponse | AxiosError;

    setLoading(true);
    setError(null);

    try {
      setLoading(true);
      setError(null);

      const body = {
        title,
        description,
        date_init,
        template_id,
        status_id,
        image,
      };

      response = await api.put(
        `/projects/${id}`,
        body,
        requestOptions
      );

      await fetchProjects(1, 'asc', 'title')
    } catch (err) {
      response = err as AxiosError;
      setError(err);
    } finally {
      setLoading(false);
    }
    return response;
  }

  async function createProjects({
    date_init,
    description,
    template_id,
    title,
    image,
    id
  }: ICreateProjectDTO): Promise<AxiosResponse | AxiosError> {
    let response: AxiosResponse | AxiosError;

    try {
      setLoading(true);
      setError(null);

      const body = {
        date_init,
        description,
        template_id,
        title,
        image,
        id
      };

      response = await api.post(
        "/projects",
        body,
        requestOptions
      );

      await fetchProjects(1, "asc", "title");
    } catch (err) {
      response = err as AxiosError;
      setError(err);
    } finally {
      setLoading(false);
    }

    return response;
  }

  async function uploadImage(image: any): Promise<AxiosResponse | AxiosError> {
    let response: AxiosResponse | AxiosError;

    try {
      setLoading(true);
      setError(null);

      response = await api.post(
        "/images/posts",
        image,
        requestOptions
      );
    } catch (err) {
      response = err as AxiosError;
      setError(err);
    } finally {
      setLoading(false);
    }

    return response;
  }

  useEffect(() => {
    fetchProjects(1, "asc", "title");
    fetchCurrentPhases();
  }, []);

  return (
    <ProjectContext.Provider
      value={{
        fetchProjects,
        fetchProjectsReports,
        fetchProjectsGantt,
        fetchCurrentPhases,
        fetchAllPhases,
        deleteProjects,
        handleModalVisible,
        updateProjects,
        createProjects,
        uploadImage,
        handleReportExportArqv,
        fetchAllProjects,
        handleUsersProjects,
        currentTask,
        isNewModalVisible,
        projects,
        projectsReport,
        totalPages,
        total,
        error,
        loading,
        currentPhases,
        allPhases,
        allProjects,
        projectsGantt,
        allUsersProjects,
        loadingGantt
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export function useProjects() {
  const context = useContext(ProjectContext);
  return context;
}
