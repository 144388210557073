import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { AxiosError, AxiosResponse } from "axios";
import { api } from "../services/api";
import { useLogged } from "./userLogin";

import { message } from 'antd'
interface IProps {
  children: ReactNode;
}

interface ITemplatesContext {
  templates: ITemplates[];
  loading: boolean;
  error: any | null;
  total?: number;
  totalPages: number;
  isNewModalVisible?: boolean;
  handleModalVisible?: (visible: boolean) => void;
  createTemplates: ({} :ICreateTemplateDTO) => Promise<AxiosResponse | AxiosError>;
  deleteTemplates: (id :string) => Promise<AxiosResponse | AxiosError>;
  cloneTemplates: (id :string) => Promise<AxiosResponse | AxiosError>;
  updateTemplates: (id: string, {}:ICreateTemplateDTO) =>Promise<AxiosResponse | AxiosError>;
  fetchTemplates: (
    page: number,
    order: string,
    orderBy: string,
    search?: string
  ) => Promise<void>;
}

export interface ITemplates {
  id: string;
  title: string;
  description: string;
  user_id: string;
  steps: string;
  ops_type: string;
  civil_type: string;
}

interface ICreateTemplateDTO {
  description: string;
  name: string;
  civil_type: string;
  ops_type: string;
  id :string,
  newSteps : string,
  type: string
}

export const TemplatesContext = createContext<ITemplatesContext>({} as ITemplatesContext);

export const TemplatesStorage = ({ children }: IProps) => {
  const [total, setTotal] = useState(0);
  const [templates, setTemplates] = useState<ITemplates[]>([]);
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const { token } = useLogged();

  async function cloneTemplates (id :string): Promise<AxiosResponse | AxiosError> {
    let response: AxiosResponse | AxiosError;
    try {
      setLoading(true);
      setError(null)
      response = await api.post(`/templates/${id}/clone`, {
        headers: {
          Authorization:  `Bearer ${token}`,
        },
      })
      await fetchTemplates(1, 'asc', 'title');
    }catch(err){
      response = err as AxiosError;
      setError(err);
    }finally {
      setLoading(false);
    } return response;
  }

  async function deleteTemplates (id :string): Promise<AxiosResponse | AxiosError> {
    let response: AxiosResponse | AxiosError;
    try {
      setLoading(true);
      setError(null)
      response = await api.delete(`/templates/${id}`, {
        headers: {
          Authorization:  `Bearer ${token}`,
        },
      })
      await fetchTemplates(1, 'asc', 'title');
    }catch(err){
      response = err as AxiosError;
      setError(err);
    }finally {
        setLoading(false);
    } return response;
  }

  async function updateTemplates(
    id: any,
    {name, civil_type, ops_type, description, newSteps} :ICreateTemplateDTO
  ): Promise<AxiosResponse | AxiosError> {
    let response: AxiosResponse | AxiosError;

    setLoading(true);
    setError(null);

    try {
      setLoading(true);
      setError(null);

      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const body = {
        title: name,
        civil_type,
        ops_type,
        description,
        type: "templates",
        steps: newSteps
      };

      response = await api.put(
        `/templates/${id}`,
        body,
        options
      );
    } catch (err) {
      response = err as AxiosError;
      setError(err);
    } finally {
      setLoading(false);
    }
    return response;
  }

  async function createTemplates({
    name,
    description,
    civil_type,
    id,
    ops_type,
    newSteps,
    type
  }: ICreateTemplateDTO): Promise<AxiosResponse | AxiosError> {
    let response: AxiosResponse | AxiosError;
    try {
      setLoading(true);
      setError(null);
      response = await api.post(
        "/templates",
        {
          title: name,
          description,
          civil_type,
          ops_type,
          id,
          steps: newSteps,
          type
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await fetchTemplates(1, 'asc', 'title');
    } catch (err) {
      response = err as AxiosError;
      setError(err);
    } finally {
      setLoading(false);
    }

    return response;
  }

  async function fetchTemplates(
    page: number,
    order: string,
    orderBy: string,
    search?: string
  ) {
    setLoading(true);
    setError(null);

    const url = search
      ? `/templates/list?page=${page}&items=7&columnOrder=${orderBy}&order=${order}&search=${search}`
      : `/templates/list?page=${page}&items=7&columnOrder=${orderBy}&order=${order}`;

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await api.get(url, options);
      setTemplates(data.data.templates);
      setTotal(data.data.templatesNumber);
      setTotalPages(data.data.totalPages);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchTemplates(1, "asc", "title");
  }, []);

  return (
    <TemplatesContext.Provider
      value={{
        fetchTemplates,
        createTemplates,
        cloneTemplates,
        deleteTemplates,
        updateTemplates,
        totalPages,
        total,
        error,
        loading,
        templates
      }}
    >
      {children}
    </TemplatesContext.Provider>
  );
};

export function useTemplates() {
  const context = useContext(TemplatesContext);
  return context;
}
