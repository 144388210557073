import styled from "styled-components";

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0;
`;

export const Kanban = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 170px);
  max-height: calc(100vh - 170px);
  overflow-x: auto;
`;

export const Phase = styled.div`
  min-width: 300px;
  padding: 0 10px;
  margin-right: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;

  .title-container {
    display: flex;
    justify-content: space-between;

    p.title {
      font-weight: 500;
      padding: 10px;
    }

    button {
      width: 22px;
      margin-top: 10px;
    }
  }
`;

export const TasksContainer = styled.div`
  height: calc(100% - 60px);
  overflow-y: scroll;
`;

export const Task = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100px;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border: 1px solid #fff;
  cursor: pointer;

  &:hover {
    border-color: #f7cd11;
  }

  .title {
    font-weight: 500;
  }

  .task_info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    label {
      font-size: 0.6rem;
    }
  }

  .task_plan {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    label {
      font-size: 0.6rem;
    }
  }
`;

export const Loading = styled.div`
  flex: 1;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

export const ModalTaskBody = styled.div`
  display: flex;
  flex-direction: column;

  .task_description {
    padding-bottom: 5px;
  }

  .task_plan,
  .task_executed {
    flex: 1;
    width: 100%;

    .ant-card {
      width: 100%;

      .ant-card-head {
        min-height: 0;
        .ant-card-head-title {
          padding: 5px;
        }
      }

      .ant-card-body {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 5px;

        .ant-col {
          /* text-align: center; */

          label {
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  .task_status {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .task_assigned_user {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .task_options {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .task_comments {
    .add_comment {
      display: flex;
      margin-bottom: 10px;
      input {
        margin-left: 10px;
      }
    }
    .comment_container {
      max-height: 200px;
      overflow-y: auto;

      .comment {
        display: flex;
        margin-bottom: 10px;
        .comment_content {
          width: 100%;
          margin-left: 10px;

          p {
            margin-bottom: 0;
          }

          div {
            background-color: #f5f5f5;
            border: 1px solid #d9d9d9;
            padding: 4px 11px;
            border-radius: 3px;
          }
        }
      }
    }
  }

  .task_attachments {
    display: flex;
    flex-direction: column;

    .add_attachment {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 10px;
    }

    .attachment_container {
      overflow-y: auto;
      max-height: 200px;

      .attachment {
        display: flex;
        margin-bottom: 10px;

        .attachment_preview_ext,
        .attachment_preview_img {
          width: 150px;
          min-height: 80px;
        }

        .attachment_preview_ext {
          display: flex;
          justify-content: center;
          align-content: center;
          align-items: center;
          font-size: 20px;
          font-weight: 600;
          background-color: #f5f5f5;
          border-radius: 5px;
          height: 80px;
        }

        .attachment_content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 10px;

          a,
          button {
            text-decoration: none;
            color: rgba(0, 0, 0, 0.5);

            &:hover {
              color: rgba(0, 0, 0, 0.8);
            }
          }

          p {
            margin: 0;
          }
        }
      }
    }
  }

  .histories_container {
    max-height: 240px;
    overflow-y: auto;

    .history {
      display: flex;
      margin-bottom: 10px;
      .history_content {
        width: 100%;
        margin-left: 10px;

        p {
          margin-bottom: 0;
        }

        div {
          background-color: #f5f5f5;
          border: 1px solid #d9d9d9;
          padding: 4px 11px;
          border-radius: 3px;
        }
      }
    }
  }
`;

export const DisplayInRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DisplayInColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const HorizontalSpace = styled.div`
  width: 10px;
`;

export const VerticalSpace = styled.div`
  height: 10px;
`;
