import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.shape};
`;

export const LoginBox = styled.div`
  min-width: 330px;
  width: 30%;
  border: 1px solid ${({ theme }) => theme.colors.text_light};
  border-radius: 1.5rem;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 10%;
  margin-bottom: 10%;

  .title {
    color: ${({ theme }) => theme.colors.text_dark};

    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;
