import { ThemeProvider } from "styled-components";

import 'antd/dist/antd.variable.min.css';
import "./styles/styles.less";

import theme from "./styles/theme";
import { UserStorage } from "./hooks/userLogin";
import { Routes } from "./routes";

function App() {
  return (
    <UserStorage>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </UserStorage>
  );
}

export default App;
