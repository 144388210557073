import { BrowserRouter } from "react-router-dom";
import { useLogged } from "../hooks/userLogin";
import { AppRoutes } from "./app.routes";
import { AuthRoutes } from "./auth.routes";

export function Routes() {
  const { user } = useLogged();

  return (
    <BrowserRouter>
      {
        user.id
          ? <AppRoutes />
          : <AuthRoutes />
      }
    </BrowserRouter>
  );
}
