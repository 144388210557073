import { Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Home } from "../pages/Home";
import { UserTable } from "../components/UsersTable";
import { TemplatesTable } from "../components/TemplatesTable";
import { GroupsTable } from "../components/GroupsTable";
import { UsersStorage } from "../hooks/useUsers";
import { RolesStorage } from "../hooks/useRoles";
import { CompaniesStorage } from "../hooks/useCompanies";
import { TemplatesStorage } from "../hooks/useTemplates";
import { ProjectsStorage } from "../hooks/useProjects";

import { Template } from "../components/NewTemplates";
import { EditTemplate } from '../components/EditTemplate'
import { ProjectsTable } from "../components/ProjectsTable";
import { ProjectDetails } from "../components/ProjectDetails";

import { useLogged } from "../hooks/userLogin";
import {Reports} from '../components/Reports';
import { Graphics } from "../components/Graphics";
import { Dashboard } from "../components/Dashboard";
import { DashboardStorage } from "../hooks/useDashboard";
import { NotificationStorage } from "../hooks/useNotifications";
import { Notification } from "../components/Notification";
import { General } from "../components/General";
import { GeneralStorage } from "../hooks/useGeneral";

export function AppRoutes() {
  const auth = useLogged();

  return (
    <RolesStorage>
      <UsersStorage>
        <CompaniesStorage>
          <TemplatesStorage>
            <ProjectsStorage>
              <DashboardStorage>
              <Helmet>
                <title>{ auth.user.company.name || 'Gestão' }</title>‍
                <meta name="description" content="" />
                <link
                  rel="icon"
                  type="image/png"
                  href={ auth.user.company.logo }
                  sizes="16x16"
                />
              </Helmet>
              <Routes>

                <Route
                  path="/"
                  element={
                    <Home />
                  }
                />

                <Route
                  path="groups/:page"
                  element={
                    <Home>
                      <GroupsTable />
                    </Home>
                  }
                />

                <Route
                  path="users/:page"
                  element={
                    <Home>
                      <UserTable />
                    </Home>
                  }
                />

                <Route
                  path="templates/:page"
                  element={
                    <Home>
                      <TemplatesTable />
                    </Home>
                  }
                />

                <Route
                  path="create/template/"
                  element={
                    <Home>
                      <Template />
                    </Home>
                  }
                />

                <Route
                  path="edit/template/"
                  element={
                    <Home>
                      <EditTemplate />
                    </Home>
                  }
                />

                <Route
                  path="projects/:page"
                  element={
                    <Home>
                      <ProjectsTable />
                    </Home>
                  }
                />

                <Route
                  path="project-details/:id"
                  element={
                    <Home>
                      <ProjectDetails />
                    </Home>
                  }
                />

                <Route
                  path="project-details/:id/:taskId"
                  element={
                    <Home>
                      <ProjectDetails />
                    </Home>
                  }
                />

                <Route
                  path="reports/:page"
                  element={
                    <Home><Reports /></Home>
                  }
                />

                  <Route
                    path="reports/:page/:userId"
                    element={
                      <Home><Reports /></Home>
                    }
                  />

                <Route
                  path="reports/highcharts"
                  element={
                    <Home>
                      <Graphics />
                    </Home>
                  }
                />
                <Route
                  path="dashboard"
                  element={
                    <Home>
                      <Dashboard />
                    </Home>
                  }
                />

                <Route
                  path="notifications/:page"
                  element={
                    <Home>
                      <NotificationStorage>
                        <Notification />
                      </NotificationStorage>
                    </Home>
                  }
                />

                <Route
                  path="general"
                  element={
                    <Home>
                        <GeneralStorage>
                          <General />
                        </GeneralStorage>
                    </Home>
                  }
                />

              </Routes>
              </DashboardStorage>
            </ProjectsStorage>
          </TemplatesStorage>
        </CompaniesStorage>
      </UsersStorage>
    </RolesStorage>
  );
}
