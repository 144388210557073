import { useEffect, useState } from "react";
import { Kanban } from "../ProjectDetails/styles";
import { useGeneral, IProjects } from "../../hooks/useGeneral";
import _ from "lodash";
import { Phase, Project, ProjectContainer } from "./styles";
import { Button, Col, Row, Select, Tooltip } from "antd";
import { CalendarOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";

interface IProjectsProp {
  projects: Array<IProjects>;
}

const Projects = ({ projects }: IProjectsProp) => {
  return (
    <>
      {
        projects.map((project: IProjects) => (
          <a href={"/project-details/" + project.project_id} style={{
            color: "inherit"
          }}>
            <Project
              key={project.project_id}
              className="project"
            >
              <img className="project-image" src={project.project_image} alt={project.project_title} width="300" height="200" />

              <p className="title">
                {project.project_title}
              </p>
              <div className="project_info">
                <Tooltip placement="top" title="Responsável pelo Projeto">
                  <label><UserOutlined /> {project.user_responsable}</label>
                </Tooltip>
                <div className="project_plan">
                  <Tooltip placement="top" title="Data de encerramento">
                    <label><CalendarOutlined /> {moment(project.result_finish_date.split('T')[0]).format('DD/MM/YYYY')}</label>
                  </Tooltip>
                </div>
              </div>
            </Project>
          </a>
        ))
      }
    </>
  )
};

export function General() {
  const [searchUsersProjects, setSearchUsersProjects] = useState<string>("");

  const {
    projectsPhases,
    fetchProjectPhases,
    users,
    fetchUsers,
  } = useGeneral();

  const fetchDebounce = _.debounce(() => {
    fetchProjectPhases();
    fetchUsers();
  }, 500);

  useEffect(() => {
    fetchDebounce();
  }, []);

  async function handleSearch() {
    fetchProjectPhases(searchUsersProjects);
  }

  return (
    <>
      <Row>
        <Col span={16}>
          <div style={{ flexDirection: 'row' }}>
            <Row>
              <Col span={16}>
                <label> Buscar por Responsável (nome):</label>
                <Row>
                  <Select
                    style={{ flex: 1 }}
                    placeholder="Filtrar por responsável"
                    onChange={e => setSearchUsersProjects(e)}
                    mode="multiple"
                    allowClear
                  >
                    {
                      users?.map(user => (
                        <Select.Option value={user.id}>{user.name}</Select.Option>
                      ))
                    }
                  </Select>
                  <Button
                    type="primary"
                    onClick={handleSearch}
                    style={{ width: 200 }}
                  >
                    <SearchOutlined />
                    Buscar
                  </Button>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Kanban>
        {
          projectsPhases && projectsPhases.map(data => {
            if (data.projects.length > 0) {
              return (
                <Phase>
                  <div className="title-container">
                    <p className="title">{data.phase_title}</p>
                  </div>

                  <ProjectContainer>
                    <Projects projects={data.projects} />
                  </ProjectContainer>

                </Phase>
              )
            } else {
              return (<></>)
            }
          })
        }
      </Kanban>
    </>
  );
}