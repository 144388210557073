import React, { ReactNode } from 'react';
import { Modal } from 'antd';

interface IProps {
  buttonModalText: string;
  children: ReactNode;
  visible: boolean;
  title: string;
  handleOk?: () => void;
  handleCancel: () => void;
}

export function NewModal({
  children,
  handleCancel,
  handleOk,
  title,
  visible
} : IProps) {
  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      {children}
    </Modal>
  );
};
