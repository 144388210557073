import { useEffect, useRef } from "react";
import { useDashboard } from "../../hooks/useDashboard";
import { DashboardWrapper, Square, SpanTitle, SpanQuantity, ChartsContainer } from './styles';
import Highcharts from "highcharts/highcharts-gantt";
import HighchartsReact from 'highcharts-react-official';

import * as _ from 'lodash';

export function Dashboard(props: HighchartsReact.Props) {
  const {
    phasesProject,
    projectsNumber,
    finishedProjects,
    fetchPhasesProject,
    dataPercentageToCompleteProjects,
    percentageToCompleteProjects,
    userProjectAndTasksNotComplete,
    dataUserProjectAndTasksNotComplete,
  } = useDashboard();

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const fetchDashboardData = _.debounce(() => {
    fetchPhasesProject();
    percentageToCompleteProjects();
    userProjectAndTasksNotComplete();
  }, 500);


  useEffect(() => {
    fetchDashboardData();
  }, []);

  const seriesUsers = [
    {
      name: "Projetos Não Finalizados",
      data: dataUserProjectAndTasksNotComplete.map((dataUser) => dataUser.projectsNotComplete),
    },
    {
      name: "Tarefas Não Finalizadas",
      data: dataUserProjectAndTasksNotComplete.map((dataUser) => dataUser.tasksNotComplete),
    }
  ];

  const optionsProjectNotComplete = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Projetos não finalizados',
      align: 'left',
    },
    xAxis: {
      categories: dataPercentageToCompleteProjects.map((data) => data.projectName),
      crosshair: true,
      accessibility: {
        description: 'Projetos',
      },
    },
    legend: {
      enabled: false
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Porcentagem',
      },
    },
    tooltip: {
      formatter(): any {
        // @ts-ignore
        return '<b>' + this.x + '</b>: ' + this.y + '%';
      },
    },
    series: [{
      data: dataPercentageToCompleteProjects.map((data) => ({
        name: data.projectName,
        y: data.percentageToComplete
      }))
    }]
  };

  const optionsUserProjectsAndTasksNotComplete = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Projetos e Tarefas Não Finalizados por Usuário',
      align: 'left',
    },
    xAxis: {
      categories: dataUserProjectAndTasksNotComplete.map((dataUser) => dataUser.userName),
      crosshair: true,
      accessibility: {
        description: 'Projetos',
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Quantidade',
      },
    },
    series: seriesUsers
  };

  return (
    <div>
      <DashboardWrapper>
        <Square key="projectsNumber">
          <SpanTitle>Total de Projetos</SpanTitle>
          <SpanQuantity>{projectsNumber}</SpanQuantity>
        </Square>

        <Square key="finishedProjects">
          <SpanTitle>Entregue</SpanTitle>
          <SpanQuantity>{finishedProjects}</SpanQuantity>
        </Square>
        {phasesProject.map((item, index) => (
          <Square key={index} >
            <SpanTitle>{item.title}</SpanTitle>
            <SpanQuantity>{item.quantity}</SpanQuantity>
          </Square>
        ))}
      </DashboardWrapper>

      <ChartsContainer className="charts-container">
        <HighchartsReact
          highcharts={Highcharts}
          options={optionsProjectNotComplete}
          ref={chartComponentRef}
          {...props}
        />

        <HighchartsReact
          highcharts={Highcharts}
          options={optionsUserProjectsAndTasksNotComplete}
          ref={chartComponentRef}
          {...props}
        />
      </ChartsContainer>

    </div>
  );
}