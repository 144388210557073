import { useEffect, useState } from "react";
// Import Highcharts
import Highcharts from "highcharts/highcharts-gantt";
import HighchartsReact from "highcharts-react-official";
import { useProjects } from "../../hooks/useProjects";
import HC_exporting from 'highcharts/modules/exporting';
import * as _ from 'lodash';

import {
  Button,
  Col,
  Row,
  Select,
  Space,
  Spin
} from "antd";
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";

export const Graphics = () => {
  const [search, setSearch] = useState<any>();
  const [searchPhase, setSearchPhase] = useState<any>();
  const [exibition, setExibition] = useState<boolean>(false)
  const {
    loadingGantt,
    fetchAllProjects,
    allProjects,
    fetchProjectsGantt,
    projectsGantt,
    fetchAllPhases,
    allPhases,
  } = useProjects();

  const [chartOptions, setChartOptions] = useState({});

  function handleExport() {
  }

  async function handleSearch() {
    fetchProjectsGantt(search, searchPhase);
    setExibition(true)
    if(!search.length){
      setExibition(false);
    }
  }


  function handleCleanFilter () {
    window.location.reload();
  }

  const fetchProjectDebounce = _.debounce((params) => {
    fetchProjectsGantt(params);
    fetchAllPhases()
    setExibition(true)
  }, 500);

  useEffect(() => {
    if(allProjects){
      setExibition(false)
      const all = allProjects.map((e) => e.id)
      fetchProjectDebounce(all)
    }
  }, [allProjects.length < 1]);


  HC_exporting(Highcharts);

  useEffect(() => {
    fetchAllProjects();
  }, []);

  useEffect(() => {
    const dateMax = projectsGantt.reduce((a: any, c: any) => {
      if (a == null || c.end > a) {
        a = c.end;
        a = a
      }
      return a;
    }, null);

    const dateMin = projectsGantt.reduce((a: any, c: any) => {
      if (a == null || c.start < a) {
        a = c.start;
        a = a
      }
      return a;
    }, null);

    setChartOptions({
      title: {
        text: 'Gráficos de Projetos'
      },
      yAxis: {
        labels: {
          // format: '{value: teste %m}'
        }
      },
      xAxis: {
        min: dateMin,
        max: dateMax,
        labels: {
          // format: '{value: XXX  %m}',
        },
      },
      accessibility: {
        keyboardNavigation: {
          seriesNavigation: {
            mode: 'serialize'
          }
        },
        point: {
          descriptionFormatter: function (point: any) {
            var dependency = point.dependency &&
              point.series.chart.get(point.dependency).name,
              dependsOn = dependency ? ' Depends on ' + dependency + '.' : '';

            return Highcharts.format(
              '{point.yCategory}. Início {point.x:%Y-%m-%d}, Final {point.x2:%Y-%m-%d}.{dependsOn}',
              { point, dependsOn }
            );
          }
        },
      },
      lang: {
        accessibility: {
          axis: {
            xAxisDescriptionPlural: 'The chart has a two-part X axis showing time in both week numbers and days.'
          }
        },

      },
      series: [{
        name: 'Projects',
        data: projectsGantt,
        start: 'início',
      }],
      scrollbar: { enabled: true, liveRedraw: true },
    });

    Highcharts.setOptions({
      lang: {
        months: [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro'
        ],
        shortMonths: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez'
        ],
        weekdays: [
          'Domingo',
          'Segunda',
          'Terça',
          'Quarta',
          'Quinta',
          'sexta',
          'sábado'
        ],
        shortWeekdays: [
          'Dom',
          'Seg',
          'Tec',
          'Qua',
          'Qui',
          'Sex',
          'Sab'
        ],
        contextButtonTitle: 'Exportar gráfico',
        decimalPoint: ',',
        thousandsSep: '.',
        downloadJPEG: 'Baixar imagem JPEG',
        downloadPDF: 'Baixar arquivo PDF',
        downloadPNG: 'Baixar imagem PNG',
        downloadSVG: 'Baixar vetor SVG',
        downloadCSV: 'Baixar arquivo CSV',
        downloadXLS: 'Baixar arquivo XLS',
        printChart: 'Imprimir gráfico',
        rangeSelectorFrom: 'De',
        rangeSelectorTo: 'Para',
        rangeSelectorZoom: 'Zoom',
        resetZoom: 'Limpar Zoom',
        resetZoomTitle: 'Voltar Zoom para nível 1:1',
        exitFullscreen: 'Sair da tela cheia',
        viewFullscreen: 'Ver em tela cheia',
        viewData: 'Ver tabela',
        noData: 'Sem dados para visualizar',
        hideData: 'Esconder tabela',
      },
      tooltip: {
        formatter: function() {
          const point: any = this.point;
          return `
            <b>${point.name}</b>
            <br />
            Início: ${moment(point.options.date_start.split('T')[0]).format('DD/MM/YYYY')}
            <br />
            Fim: ${moment(point.options.date_end.split('T')[0]).format('DD/MM/YYYY')}
          `;
        }
      }
    });
  }, [projectsGantt]);

  return (
    <>
      {
        loadingGantt
          ?
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Space size="middle">
              <Spin />
            </Space>
          </div>
          :
          <div>
            <Row>
              <Col span={24}>
                <label> Buscar por Projetos (nome):</label>
                <Row style={{ display: "flex", justifyContent: "space-between"}}>
                  <Select
                    style={{ flex: 1 }}
                    placeholder="Filtrar por projeto"
                    onChange={e => setSearch(e)}
                    mode="multiple"
                    value={search}
                  >
                    {
                      allProjects
                        .map(phase => <Select.Option key={phase.id}>{phase.title}</Select.Option>)
                    }
                  </Select>

                  <Select
                    style={{ flex: 1 }}
                    placeholder="Filtrar por etapa"
                    onChange={e => setSearchPhase(e)}
                    mode="multiple"
                    value={searchPhase}
                  >
                    {
                      allPhases
                        .map(phase => <Select.Option key={phase.phase_title}>{phase.phase_title}</Select.Option>)
                    }
                  </Select>

                  <Col span={4} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", alignContent: 'flex-end', marginLeft: 10 }}>
                    <Button
                      type="primary"
                      onClick={handleSearch}
                      style={{ width: 200 }}
                    >
                      <SearchOutlined />
                      Buscar
                    </Button>
                  </Col>


                  <Col span={4} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", alignContent: 'flex-end' }}>
                    <Button
                      onClick={handleCleanFilter}
                      style={{ width: 200 }}
                    >
                      <DeleteOutlined />
                      Limpar filtros
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            {
              exibition &&
              <HighchartsReact
                highcharts={Highcharts}
                constructorType={"ganttChart"}
                options={chartOptions}
                ref={handleExport}
              />
            }
          </div>
      }
    </>
  );
};

