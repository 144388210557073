import { InputHTMLAttributes } from "react";
import { HTMLAttributes } from "react";
import { Error } from "../../Error";
import { Container } from "./styles";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  type: string;
  name: string;
  error: string;
}

export function Input({ type, name, error, ...rest }: IProps) {
  return (
    <Container>
      <input
        type={type}
        className="input"
        name={name}
        id="email-input"
        {...rest}
      />
      <Error>{error}</Error>
    </Container>
  );
}
