import { HTMLAttributes, HTMLProps } from "react";
import { Container } from "./styles";

interface IProps {
  children: React.ReactNode;
}

export function Error({ children, ...rest }: IProps) {
  return <Container>{children}</Container>;
}
