import { useEffect, useState } from "react";
import { useRoles } from "../../hooks/useRoles";

import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  message,
  Popconfirm,
  Select,
  Space,
  Table,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import FormItem from "antd/lib/form/FormItem";
import { NewModal } from "../NewModal/index";

interface IEditRole {
  id: string;
  description: string;
  name: string;
  permissions: Array<any>;
  availablePermissions: Array<any>;
  onSave?: any;
  onCancel?: any;
}
const FormEdit = ({ description, name, permissions, availablePermissions, onSave, onCancel }: IEditRole) => (
  <Form
    onFinish={onSave}
    initialValues={{description, name, permissions}}
  >
    <Form.Item
      name="name"
      label="Nome"
      style={{
        marginBottom: "25px",
      }}
      rules={[
        {
          required: true,
          message: "Este campo é obrigatório!",
        },
      ]}
      initialValue={name}
    >
      <Input
        maxLength={50}
        placeholder="Nome do grupo!"
      />
    </Form.Item>

    <FormItem
      name="description"
      label="Descrição"
      style={{
        marginBottom: "25px",
      }}
      rules={[
        {
          required: true,
          message: "Este campo é obrigatório!",
        },
      ]}
      initialValue={description}
    >
      <Input
        maxLength={50}
        placeholder="Descrição do grupo!"
      />
    </FormItem>

    <FormItem
      name="permissions"
      label="Permissões"
      style={{
        marginBottom: "25px",
      }}
      rules={[
        {
          required: true,
          message: "Este campo é obrigatório!",
        },
      ]}
      initialValue={permissions}
    >
      <Select
        mode="multiple"
        placeholder="Selecione as permissões!"
        style={{ width: "100%" }}
      >
        {availablePermissions.map((item) => (
          <Select.Option key={item.id} value={item.id}>
            {item.alias}
          </Select.Option>
        ))}
      </Select>
    </FormItem>

    <FormItem style={{ paddingLeft: "63%" }}>
      <Button type="primary" htmlType="submit">
        Salvar
      </Button>
      <Button onClick={onCancel}>Cancelar</Button>
    </FormItem>
  </Form>
);

interface IRowItem {
  name: string;
  description: string;
  company: string;
}
export function GroupsTable() {
  const currentRoute = useParams();
  const navigate = useNavigate();

  const {
    roles,
    permissions,
    deleteRole,
    updateRole,
    fetchRoles,
    handleModalVisible,
    total,
    loading,
  } = useRoles();

  const editRole =
    permissions.filter((item) => item.name === "editRole").length > 0;

  const [visibleEdit, setVisibleEdit] = useState(false);
  const [page, setPage] = useState<number>(Number(currentRoute.page));
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState<string>("name");
  const [formDefault, setFormDefault] = useState<IEditRole>({} as IEditRole);
  const [search, setSearch] = useState<string>("");
  const data = roles.map((item) => {
    return {
      name: item.name,
      description: item.description,
      company: item.company?.name,
    };
  });

  useEffect(() => {
    if (search != "") {
      fetchRoles(page, order, orderBy, search);
    } else {
      fetchRoles(page, order, orderBy);
    }
  }, [page]);

  const handleDelete = async (record: IRowItem) => {
    const index = data.indexOf(record);
    const status: any = await deleteRole(roles[index].id);

    if (status?.response?.status == 406) {
      return message.warning("Existem usuários neste grupo!");
    }

    if (status?.response?.status == 500) {
      return message.warning("Falha!");
    }

    message.success("Excluído!");

    fetchRoles(page, order, orderBy);
  };

  const handleTableChange = async (pagination: any, filters: any, sorter: any, extra: any) => {
    if (sorter.field) setOrderBy(sorter.field);

    if (sorter.order === "descend") {
      if (search != "") {
        fetchRoles(page, "desc", sorter.field, search);
        setOrder("desc");
        setOrderBy(sorter.field ? sorter.field : "name");
      } else {
        fetchRoles(page, "desc", sorter.field);
        setOrder("desc");
        setOrderBy(sorter.field ? sorter.field : "name");
      }
    } else {
      if (search != "") {
        fetchRoles(page, "asc", sorter.field, search);
        setOrder("asc");
        setOrderBy(sorter.field ? sorter.field : "name");
      } else {
        fetchRoles(page, "asc", sorter.field);
        setOrder("asc");
        setOrderBy(sorter.field ? sorter.field : "name");
      }
    }
  }

  const handleShowModal = (record: IRowItem) => {
    const role = roles.find((item) => item.name === record.name);
    if (role) {
      setFormDefault({
        name: role?.name,
        description: role?.description,
        permissions: role?.permissions.map(p => p.id),
        availablePermissions: permissions,
        id: role?.id,
      });
      setVisibleEdit(true);
    }
  }

  const handleSaveEdit = async (id: string, form: any) => {
    setVisibleEdit(false);

    const status: any = await updateRole(id, {
      description: form.description,
      name: form.name,
      permissionsIds: form.permissions,
    });

    if (status?.status === 200) {
      fetchRoles(page, order, orderBy);
      return message.success("Editado!");
    }

    if (status?.response?.status == 406) {
      return message.warning("Existem usuários nesse grupo!");
    }

    message.error("Falha!");
  }

    const columns: any = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      sorter: (a: IRowItem, b: IRowItem) => a.name.localeCompare(b.name),
      filterDropdown: () => (
        <div>
          <Input
            maxLength={50}
            placeholder="Buscar nome"
            onChange={({ target }) => setSearch(target.value)}
            style={{
              width: "9rem",
            }}
          />
          <Button
            type="primary"
            onClick={() => fetchRoles(page, order, orderBy, search)}
          >
            Buscar
          </Button>
        </div>
      ),
      filterIcon: (
        <SearchOutlined
          style={{
            fontSize: 17,
          }}
        />
      ),
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
      sorter: (a: IRowItem, b: IRowItem) =>
        a.description.localeCompare(b.description),
    },
  ];

  editRole &&
    columns.push({
      title: "Ações",
      key: "action",
      render: (_: any, record: IRowItem) => (
        <Space size="middle">
          <a
            style={{
              color: "#F7CD11",
            }}
            onClick={() => handleShowModal(record)}
          >
            Editar
          </a>

          <Popconfirm
            title="Você tem certeza?"
            onConfirm={() => handleDelete(record)}
            okText="Sim"
            cancelText="Não"
          >
            <a
              style={{
                color: "#ff0000",
              }}
            >
              Deletar
            </a>
          </Popconfirm>
        </Space>
      ),
    });

  return (
      <div>
        {/* {editRole && (
          <Space style={{ marginBottom: 16, marginTop: 15 }}>
            <Button onClick={() => handleModalVisible(true)}>Novo grupo</Button>
          </Space>
        )} */}
        <Table
          showSorterTooltip={{ title: "Clique para ordenar" }}
          dataSource={data}
          columns={columns}
          rowKey={(item) => item.name}
          onChange={handleTableChange}
          loading={loading}
          pagination={{
            position: ["bottomRight"],
            pageSize: 7,
            current: Number(page),
            total: total,
            onChange: (pageAux) => {
              setPage(pageAux);
              navigate(`/groups/${pageAux}`);
            },
          }}
        />

        <NewModal
          buttonModalText={"Editar"}
          visible={visibleEdit}
          handleCancel={() => setVisibleEdit(false)}
          handleOk={() => null}
          title="Editar grupo"
        >
        <FormEdit
            id={formDefault.id}
            description={formDefault.description}
            name={formDefault.name}
            permissions={formDefault.permissions}
            availablePermissions={formDefault.availablePermissions}
            onSave={(form: any) => handleSaveEdit(formDefault.id, form)}
            onCancel={() => setVisibleEdit(false)}
          />
        </NewModal>
      </div>
  );
}
