import { useEffect, useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import FormItem from "antd/lib/form/FormItem";
import {
  Tree,
  Row,
  Form,
  Input,
  Button,
  Select,
  Popconfirm,
  message,
} from "antd";
import "moment/locale/pt-br";
import { useTemplates } from "../../hooks/useTemplates";
import { NewModal } from "../NewModal";
import {
  Container,
  Item,
  Initial,
  Text,
  Infos,
  ButtonAdd,
  ButtonOrder,
} from "./styles";

interface ITask {
  id: number;
  type: string;
  title: string;
  duration: number;
  description: string;
  days: string;
  tasks?: ITask[];
}

interface IData {
  id: string;
  name: string;
  description: string;
  civil_type: string;
  ops_type: string;
  type: string;
  steps: Array<{
    id: number;
    type: string;
    title: string;
    description: string;
    tasks: ITask[];
  }>;
}

interface Istate{
  title: string,
  civil_type: string,
  description: string,
  ops_type: string,
  id: string,
  steps:any
}

export function EditTemplate() {
  let breakRecursive: boolean = false;
  const navigate = useNavigate();
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [confirmStepVisible, setConfirmStepVisible] = useState(false);
  const [projectVisible, setProjectVisible] = useState(false);
  const [stepVisible, setStepVisible] = useState(false);
  const [taskVisible, setTaskVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [selectedStep, setSelectedStep] = useState<number | null>(null);
  const [selectedTasks, setSelectedTasks] = useState<number[] | null>(null);
  const [step, setStep] = useState<any>();
  const [task, setTask] = useState<any>();
  const [auxTask, setAuxTask] = useState<any>(null);
  const [stepForm] = Form.useForm();
  const [taskForm] = Form.useForm();
  const [selected1, setSelected1] = useState('');
  const [selected2, setSelected2] = useState('');
  const [textArea, setTextArea] = useState('');
  const [templateForm] = Form.useForm();
  const { updateTemplates } = useTemplates();
  const {state} = useLocation();   //pegando dados da tela anterior e atribuindo aos campos vazios da atual

  const tryDataNew =  (state as Istate).steps && (state as any ).steps;
  const tryDataNewOk =  tryDataNew !== '' ? JSON.parse(tryDataNew) : [];

  const [data, setData] = useState<IData[]>([
    {
      id: (state as Istate).id,
      name: (state as Istate).title,
      description: (state as Istate).description,
      civil_type: (state as Istate).civil_type,
      ops_type: (state as Istate).ops_type,
      type: "templates",
      steps: tryDataNewOk.map((function(e: any) {
       return {
          title: e.title,
          id: e.id,
          type: e.type,
          description: e.description,
          tasks: e.tasks
       }
      }))
    }
  ]);

  const { civil_type, description, id, ops_type, steps, name, type } = data[0]
  const newSteps = JSON.stringify(steps)
  const { TextArea } = Input;

  useEffect(() => {
    stepForm.setFieldsValue({
      description: step?.description,
      name: step?.title,
    });
  }, [stepForm, step]);

  useEffect(() => {
    if (task) {
      taskForm.setFieldsValue({
        name: task?.title,
        days: task?.days,
        description: task?.description,
        duration: task?.duration,
      });
    }
  }, [taskForm, task]);

  async function handleStepCancel() {
    setStepVisible(false);
    setSelectedStep(null);
    setStep(null);
  };

  function handleAddStep(form: { title: string, description: string, index: number }) {
    const aux = data;

    if (form.index >= 0) {
      aux[0].steps[form.index] = {
        ...aux[0].steps[form.index],
        ...form
      };
    } else {
      aux[0]?.steps?.push({
        ...form,
        type: "step",
        id: Math.random(),
        tasks: [],
      });
    }

    setData(aux);
    setStepVisible(false);
  }

  async function handleDeleteStep(index: number) {
    const aux = data;

    if (index > -1) {
      aux[0].steps!.splice(index, 1);
    }

    setData(aux);
    handleStepCancel();
  }

  function recursiveTask(
    tasks: ITask[],
    stepIndex: number,
    parents: number[] = []
  ): any {
    return tasks.map((task, index) => {
      return {
        key: task.id,
        title: (
          <Item isItem="task">
            <Initial
              isItem="task"
              onClick={() => {
                setTaskVisible(true);
                setTask(task);
                setTitle("Tarefa");
                setSelectedTasks([...parents, index]);
                setSelectedStep(stepIndex);
              }}
            >
              {task.title.charAt(0)}
            </Initial>
            <Text
              isItem="task"
              onClick={() => {
                setTaskVisible(true);
                setTask(task);
                setTitle("Tarefa");
                setSelectedTasks([...parents, index]);
                setSelectedStep(stepIndex);
              }}
            >
              {task.title} ({ task.days.toLowerCase() === 'calendar_days' ? 'DC' : 'DU' }: { task.duration })
            </Text>
            <ButtonAdd
              type={index % 2 == 0 ? "default" : "primary"}
              onClick={() => {
                taskForm.resetFields();
                setTitle("Nova tarefa dependente");
                setSelectedTasks([...parents, index]);
                setSelectedStep(stepIndex);
                setTaskVisible(true);
                setAuxTask(task);
                setTask(null);
              }}
            >
              tarefa encadeada
            </ButtonAdd>
          </Item>
        ),
        children:
          task.tasks && task.tasks.length > 0
            ? recursiveTask(task.tasks, stepIndex, [...parents, index])
            : [],
      };
    });
  }

  function recursiveTaskUpdate(currentTask: ITask, form: any) {
    if (currentTask) {
      if (task!.id! === currentTask!.id! && currentTask?.tasks?.length! === 0) {
        const aux = data;

        const index = aux[0].steps![selectedStep!].tasks.indexOf(currentTask);

        aux[0].steps![selectedStep!].tasks[index]! = {
          ...currentTask.tasks![index]!,
          ...form,
          title: form.name,
        };

        setData(aux);
      } else if (
        task!.id! === currentTask?.id! &&
        currentTask?.tasks!.length! > 0
      ) {
        message.error("Existem tarefas que dependem dessa!");
      } else {
        currentTask!.tasks!.map((item, index) => {
          if (String(task.id) == String(item.id)) {
            currentTask!.tasks![index] = {
              ...currentTask.tasks![index]!,
              ...form,
              title: form.name,
            };
          } else if (item.tasks?.length! > 0) {
            recursiveTaskUpdate(currentTask?.tasks![index], form);
          }
        });
      }
    }
  }

  function recursiveTaskDelete(currentTask: ITask) {
    if (currentTask) {
      if (task!.id! === currentTask!.id! && currentTask?.tasks?.length! === 0) {
        const aux = data;

        const index = aux[0].steps![selectedStep!].tasks.indexOf(currentTask);

        aux[0].steps![selectedStep!].tasks.splice(index, 1);

        setData(aux);
      } else if (
        task!.id! === currentTask?.id! &&
        currentTask?.tasks!.length! > 0
      ) {
        message.error("Existem tarefas que dependem dessa!");
      } else {
        currentTask!.tasks!.map((item, index) => {
          if (String(task.id) == String(item.id)) {
            if (item?.tasks?.length! > 0) {
              message.error("Existem tarefas que dependem dessa!");
            } else {
              currentTask!.tasks?.splice(index, 1);
              message.success("Deletado");
            }
          } else if (item.tasks?.length! > 0) {
            recursiveTaskDelete(currentTask?.tasks![index]);
          }
        });
      }
    }
  }

  function recursiveTaskAdd(currentTask: ITask, form: any) {
    if (currentTask && !breakRecursive) {
      if (currentTask.id === auxTask.id) {
        currentTask.tasks?.push({
          days: form.days,
          description: form.description,
          duration: form.duration,
          title: form.name,
          type: form.type,
          tasks: [],
          id: Math.random() * 10,
        });
      } else {
        if (currentTask.tasks?.length! > 0) {
          currentTask.tasks!.map((item, index) => {
            if (String(auxTask.id) == String(item.id)) {
              currentTask.tasks![index].tasks!.push({
                days: form.days,
                description: form.description,
                duration: form.duration,
                title: form.name,
                type: form.type,
                tasks: [],
                id: Math.random() * 10,
              });

              breakRecursive = true;
            } else if (item.tasks?.length! > 0) {
              recursiveTaskAdd(currentTask?.tasks![index], form);
            }
          });
        }
      }
    }
  }

  function orderDown(rootIndex: number, index: number, items: any[]) {
    const item = items[index];
    let tempItems = [...items];
    tempItems.splice(index, 1);
    tempItems.splice(index+1, 0, item);

    const tempData = [...data];
    tempData[rootIndex].steps = tempItems;

    setData(tempData);
  }

  function orderUp(rootIndex: number, index: number, items: any[]) {
    const item = items[index];
    let tempItems = [...items];
    tempItems.splice(index, 1);
    tempItems.splice(index-1, 0, item);

    const tempData = [...data];
    tempData[rootIndex].steps = tempItems;

    setData(tempData);
  }

  async function handleSave() {
    if (name != '' && description != '' && ops_type != '') {
      await updateTemplates(id, {
        name,
        civil_type,
        ops_type,
        description,
        newSteps,
        type,
        id
      });
      navigate(`/templates/1`);
    }
    else {
      templateForm.validateFields();
    }
  }

  function handleCancel() {
    navigate('/templates/1');
  }

  return (
    <Container>
      <Form form={templateForm}>
        <Row justify="space-between">
          <Infos >
            <Form.Item
              name="title"
              label="Titulo"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Este campo é obrigatório!",
                },
              ]}
              initialValue={data[0].name}
            >
              <Input placeholder="Titulo do projeto" defaultValue={data[0].name}
                onChange={(e) => {
                  setData([{ ...data[0], name: e.target.value }]);
                }}
                value={(state as Istate).title}
              />
            </Form.Item>

            <FormItem
              name="civil_type"
              label="Tipo civil"
              style={{
                marginBottom: "25px",
              }}
              rules={[
                {
                  required: true,
                  message: "Este campo é obrigatório!",
                },
              ]}
            >
              <Select
                placeholder="Selecione o tipo civil"
                onChange={(e) => {
                  setData([{ ...data[0], civil_type: e }])
                  setSelected1(e)
                }}
                value={selected2}
                style={{ width: "100%" }}
                defaultValue={data[0].civil_type}

              >
                <Select.Option value="Construção nova">Construção nova</Select.Option>
                <Select.Option value="Reforma">Reforma</Select.Option>
                <Select.Option value="Virada de bandeira">Virada de bandeira</Select.Option>
              </Select>
            </FormItem>

            <FormItem
              name="ops_type"
              label="Tipo operação"
              style={{
                marginBottom: "25px",
              }}
              rules={[
                {
                  required: true,
                  message: "Este campo é obrigatório!",
                },
              ]}
            >
              <Select
                placeholder="Selecione o tipo de operação!"
                onChange={(e) => {
                  setData([{ ...data[0], ops_type: e }])
                  setSelected2(e)
                }}
                value={selected1}
                style={{ width: "100%" }}
                defaultValue={data[0].ops_type}
              >
                <Select.Option value="Open point">Open point</Select.Option>
                <Select.Option value="Relocation">Realocação</Select.Option>
                <Select.Option value="Renovation">Renovação</Select.Option>
              </Select>
            </FormItem>

            <Form.Item
              name="description"
              label="Descrição"
              style={{
                marginBottom: "25px",
              }}
              rules={[
                {
                  required: true,
                  message: "Este campo é obrigatório!",
                },
              ]}
            >
              <TextArea
                defaultValue={data[0].description}
                style={{ width: "100%" }}
                placeholder="Nome da descrição"
                autoSize={{ minRows: 4, maxRows: 6 }}
                onChange={(e) => {
                  setData([{ ...data[0], description: e.target.value }])
                  setTextArea(e.target.value)
                }}
                value={textArea}
              />
            </Form.Item>

          </Infos>

          {
            name.length > 0 && (
              <Tree
                showIcon={false}
                treeData={data.map((item, index) => {
                  return {
                    key: item.id,
                    title: (
                      <Item isItem="template">
                        <Initial isItem="template">{item.name.charAt(0)}</Initial>
                        <Text isItem="template">{item.name}</Text>
                        <ButtonAdd
                          type="primary"
                          onClick={() => {
                            setTitle("Nova etapa");
                            setStep({ title: '', description: '', index: -1 });
                            stepForm.resetFields();
                            setStepVisible(true);
                          }}
                        >
                          Adicionar Etapa
                        </ButtonAdd>
                      </Item>
                    ),
                    children: item?.steps?.map((step, stepIndex) => {
                      return {
                        key: step.id,
                        title: (
                          <Item isItem="step">
                            <Initial
                              isItem="step"
                              onClick={() => {
                                setStepVisible(true);
                                setStep({...data[0].steps[stepIndex], index: stepIndex});
                                setTitle("Etapa");
                              }}
                            >
                              {step.title.charAt(0)}
                            </Initial>
                            <Text
                              isItem="step"
                              onClick={() => {
                                setStepVisible(true);
                                setStep({...data[0].steps[stepIndex], index: stepIndex});
                                setTitle("Etapa");
                              }}
                            >
                              {step.title}
                            </Text>
                            <ButtonAdd
                              type={index % 2 == 0 ? "default" : "primary"}
                              onClick={() => {
                                taskForm.resetFields();
                                setTitle("Nova tarefa");
                                setSelectedStep(stepIndex);
                                setTaskVisible(true);
                                setAuxTask(task);
                                setTask(null);
                              }}
                            >
                              Adicionar tarefa
                            </ButtonAdd>
                            {
                              stepIndex > 0 && (
                                <ButtonOrder
                                  onClick={() => orderUp(index, stepIndex, item.steps)}
                                >
                                  <UpOutlined />
                                </ButtonOrder>
                              )
                            }
                            {
                              stepIndex < item?.steps.length-1 && (
                                <ButtonOrder
                                  onClick={() => orderDown(index, stepIndex, item.steps)}
                                >
                                  <DownOutlined />
                                </ButtonOrder>
                              )
                            }
                          </Item>
                        ),
                        children: recursiveTask(step.tasks, stepIndex),
                      };
                    }),
                  };
                })}
                switcherIcon={
                  <DownOutlined
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      fontSize: "1rem",
                    }}
                  />
                }
                style={{
                  marginRight: 90,
                  marginTop: 30,
                }}
                defaultExpandAll
              />
            )
          }

        </Row>

        <Row
          style={{
            marginLeft: "3rem",
          }}
        >
          <Button type="primary" onClick={handleSave}>Salvar</Button>
          <Button onClick={handleCancel}>Cancelar</Button>
        </Row>
      </Form>

      {
        projectVisible && (
          <NewModal
            visible={projectVisible}
            title={title}
            buttonModalText="Projeto"
            handleCancel={() => { }}
            handleOk={() => { }}
          >
            <Form>
              <Form.Item
                name="name"
                label="Nome"
                style={{
                  marginBottom: "25px",
                }}
                rules={[
                  {
                    required: true,
                    message: "Este campo é obrigatório!",
                  },
                ]}
              >
                <Input maxLength={50} placeholder="Nome do projeto" />
              </Form.Item>

              <FormItem
                name="description"
                label="Descrição"
                style={{
                  marginBottom: "25px",
                }}
                rules={[
                  {
                    required: true,
                    message: "Este campo é obrigatório!",
                  },
                ]}
              >
                <Input maxLength={50} placeholder="Descrição do projeto" />
              </FormItem>

              <FormItem
                name="permissions"
                label="Tipo civil"
                style={{
                  marginBottom: "25px",
                }}
                rules={[
                  {
                    required: true,
                    message: "Este campo é obrigatório!",
                  },
                ]}
              >
                <Select
                  placeholder="Selecione o tipo!"
                  onChange={() => { }}
                  style={{ width: "100%" }}
                >
                  <Select.Option value="Construção nova">Construção nova</Select.Option>
                  <Select.Option value="Reforma">Reforma</Select.Option>
                  <Select.Option value="Virada de bandeira">Virada de bandeira</Select.Option>
                </Select>
              </FormItem>

              <FormItem
                name="permissions"
                label="Tipo operação"
                style={{
                  marginBottom: "25px",
                }}
                rules={[
                  {
                    required: true,
                    message: "Este campo é obrigatório!",
                  },
                ]}
              >
                <Select
                  placeholder="Selecione o tipo"
                  onChange={() => { }}
                  style={{ width: "100%" }}
                >
                  <Select.Option value="Open point">Open point</Select.Option>
                  <Select.Option value="relocation">Realocação</Select.Option>
                  <Select.Option value="renovation">Renovação</Select.Option>
                </Select>
              </FormItem>

              <Row style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                >
                  Salvar
                </Button>

                <Button
                  onClick={() => setProjectVisible(false)}
                >
                  Cancelar
                </Button>
              </Row>
            </Form>
          </NewModal>
        )
      }

      {
        stepVisible && (
          <NewModal
            visible={stepVisible}
            title={title}
            buttonModalText="Etapa"
            handleCancel={handleStepCancel}
            handleOk={() => { }}
          >
            <Form
              form={stepForm}
              initialValues={{...step}}
              onFinish={(form) => handleAddStep({...step, ...form})}
            >
              <Form.Item
                name="title"
                label="Nome"
                style={{
                  marginBottom: "25px",
                }}
                rules={[
                  {
                    required: true,
                    message: "Este campo é obrigatório!",
                  },
                ]}
              >
                <Input maxLength={50} placeholder="Nome da etapa" />
              </Form.Item>

              <FormItem
                name="description"
                label="Descrição"
                style={{
                  marginBottom: "25px",
                }}
                rules={[
                  {
                    required: true,
                    message: "Este campo é obrigatório!",
                  },
                ]}
              >
                <Input maxLength={50} placeholder="Descrição do etapa" />
              </FormItem>

              <Row style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                >
                  Salvar
                </Button>
                {
                  step.index >= 0 &&
                  <Popconfirm
                    title="Você tem certeza?"
                    visible={confirmStepVisible}
                    onConfirm={async () => {
                      await setConfirmStepVisible(false);
                      await handleDeleteStep(step.index);
                      await setStepVisible(false);
                    }}
                    onCancel={() => setConfirmStepVisible(false)}
                  >
                    <Button
                      onClick={() => setConfirmStepVisible(true)}
                      style={{ marginLeft: 8 }}
                    >
                      Excluir
                    </Button>
                  </Popconfirm>
                }
              </Row>
            </Form>
          </NewModal>
        )
      }

      {
        taskVisible && (
          <NewModal
            visible={taskVisible}
            title={title}
            buttonModalText="Tarefa"
            handleCancel={() => {
              setTask(null);
              setTaskVisible(false);
              setSelectedTasks(null);
              setSelectedStep(null);
            }}
            handleOk={() => { }}
          >
            <Form
              form={taskForm}
              onFinish={(form) => {
                const aux = data;

                if (!selectedTasks) {
                  aux[0]!.steps![selectedStep!].tasks.push({
                    days: form.days,
                    description: form.description,
                    duration: form.duration,
                    title: form.name,
                    type: form.type,
                    tasks: [],
                    id: Math.random() * 10,
                  });

                  setData(aux);
                } else if (auxTask?.id || task === null) {
                  if (!auxTask.id) {
                    aux[0].steps![selectedStep!].tasks.push({
                      days: form.days,
                      description: form.description,
                      duration: form.duration,
                      title: form.name,
                      type: form.type,
                      tasks: [],
                      id: Math.random() * 10,
                    });
                  } else {
                    selectedTasks!.map((index) => {
                      recursiveTaskAdd(
                        aux[0]!.steps![selectedStep!].tasks![index],
                        form
                      );
                    });
                  }

                  breakRecursive = false;
                  setAuxTask(null);
                  setData(aux);
                  setTask({});
                } else if (
                  task &&
                  selectedStep! >= 0 &&
                  selectedTasks?.length! >= 0
                ) {
                  if (selectedTasks?.length === 1) {
                    aux[0].steps![selectedStep!].tasks[selectedTasks[0]] = {
                      ...aux[0].steps![selectedStep!].tasks[selectedTasks[0]],
                      title: form.name,
                      days: form.days,
                      description: form.description,
                      duration: form.duration,
                    };
                  } else {
                    selectedTasks?.map((index) => {
                      recursiveTaskUpdate(
                        aux[0]!.steps![selectedStep!].tasks[index]!,
                        form
                      );
                    });
                  }

                  setData(aux);
                }

                setTaskVisible(false);
              }}
            >
              <Form.Item
                name="name"
                label="Nome"
                style={{
                  marginBottom: "25px",
                }}
                rules={[
                  {
                    required: true,
                    message: "Este campo é obrigatório!",
                  },
                ]}
              >
                <Input maxLength={50} placeholder="Nome da tarefa" />
              </Form.Item>

              <FormItem
                name="description"
                label="Descrição"
                style={{
                  marginBottom: "25px",
                }}
                rules={[
                  {
                    required: true,
                    message: "Este campo é obrigatório!",
                  },
                ]}
              >
                <Input maxLength={50} placeholder="Descrição da tarefa" />
              </FormItem>

              <FormItem
                name="duration"
                label="Duração da tarefa"
                style={{
                  marginBottom: "25px",
                }}
                rules={[
                  {
                    required: true,
                    message: "Este campo é obrigatório!",
                  },
                ]}
              >
                <Input
                  type="number"
                  maxLength={50}
                  placeholder="Duração da tarefa"
                />
              </FormItem>

              <FormItem
                name="days"
                label="Dias contáveis"
                style={{
                  marginBottom: "25px",
                }}
                rules={[
                  {
                    required: true,
                    message: "Este campo é obrigatório!",
                  },
                ]}
              >
                <Select
                  placeholder="Selecione os dias contáveis"
                  onChange={() => { }}
                  style={{ width: "100%" }}
                >
                  <Select.Option value="working_days">Dias úteis</Select.Option>
                  <Select.Option value="calendar_days">Dias corridos</Select.Option>
                </Select>
              </FormItem>

              <Row style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>

                <Button
                  type="primary"
                  htmlType="submit"

                >
                  Salvar
                </Button>
                {
                  !!taskForm.getFieldValue('name') &&
                  <Popconfirm
                    title="Você tem certeza?"
                    visible={confirmVisible}
                    onConfirm={async () => {
                      const aux = data;
                      await setConfirmVisible(false);
                      selectedTasks!.map((index) => {
                        recursiveTaskDelete(
                          aux[0]!.steps![selectedStep!].tasks![index]
                        );
                      });
                      await setTaskVisible(false);
                      setSelectedStep(null);
                      setData(aux);
                    }}
                    onCancel={() => setConfirmVisible(false)}
                  >
                    <Button
                      onClick={() => {
                        setConfirmVisible(true);
                      }}
                      style={{ marginLeft: 8 }}
                    >
                      Excluir
                    </Button>
                  </Popconfirm>
                }
              </Row>
            </Form>
          </NewModal>
        )
      }

    </Container>
  );
}
