import React, {
  Children,
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { api } from "../services/api";
import { useLogged } from "./userLogin";

interface IProps {
  children: ReactNode;
}

interface ICompany {
  id: string;
  name: string;
  document: string;
  description: string;
  primary_color: string;
  secondary_color: string;
  logo: string;
  created_by: string;
}

interface ICompaniesContext {
  companies: ICompany[];
  loading: boolean;
  error: string | null;
}

export const CompaniesContext = createContext<ICompaniesContext>(
  {} as ICompaniesContext
);

export const CompaniesStorage = ({ children }: IProps) => {
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

    const { token, user } = useLogged();

    async function fetchCompanies() {
      setLoading(true);
      setError(null);

      api
        .get("/companies", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setCompanies(response.data.data);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    useEffect(() => {
      if (token) fetchCompanies();
    }, [token, user]);

  return (
    <CompaniesContext.Provider value={{ companies, error, loading }}>
      {children}
    </CompaniesContext.Provider>
  );
};

export function useCompanies() {
  const context = useContext(CompaniesContext);

  return context;
}
