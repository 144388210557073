import {
  Form,
  Input,
  message,
  Button,
  ConfigProvider
} from "antd";
import Layout from "antd/lib/layout/layout";
import { Header } from "../../components/Header";
import { Dashboard, Content } from "./styles";
import LateralBar from "../../components/LateralBar";
import { ReactNode } from "react";
import { useLogged } from "../../hooks/userLogin";
import { useUser } from "../../hooks/useUsers";
import { NewModal } from "../../components/NewModal";
import { NewUserModal } from "../../components/NewUserModal";
import { NewGroupsModal } from "../../components/NewGroupsModal";
import { NewProjectModal } from "../../components/NewProjectModal";

interface EditModal {
  name: string;
  email: string;
  onFinish: any;
  onCancel: any;
};
function FormEditModal({ name, email, onFinish, onCancel }: EditModal) {
  return (
    <Form
      onFinish={onFinish}
      initialValues={{ name, email, password: '', confirmPassword: '' }}
    >
      <Form.Item
        name="name"
        label="Nome"
        style={{
          marginBottom: "25px",
        }}
        initialValue={name}
      >
        <Input
          maxLength={50}
          placeholder="Digite seu nome!"
        />
      </Form.Item>

      <Form.Item
        name="email"
        label="E-mail"
        style={{
          marginBottom: "25px",
        }}
        initialValue={email}
      >
        <Input
          maxLength={50}
          placeholder="Digite seu e-mail!"
        />
      </Form.Item>

      <Form.Item
        name="password"
        label="Nova senha"
        style={{
          marginBottom: "25px",
        }}
        initialValue={''}
      >
        <Input
          type='password'
          maxLength={50}
          placeholder="Digite sua senha!"
        />
      </Form.Item>

      <Form.Item
        rules={[
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('password') === value) { return Promise.resolve(); }
            return Promise.reject(new Error('As duas senhas devem ser iguais'));
          },
        }),]}
        name="confirmPassword"
        label="Confirme sua nova senha"
        style={{
          marginBottom: "25px",
        }}
        initialValue={''}
      >
        <Input
          maxLength={50}
          type='password'
          placeholder="Confirme sua senha!"
        />
      </Form.Item>

      <div style={{ textAlign: 'right' }}>
      <Button type="primary" htmlType="submit">
        Salvar
      </Button>

      <Button onClick={onCancel}>Cancelar</Button>
      </div>

    </Form>
  )
};

interface IProps {
  children?: ReactNode;
}
export function Home({ children }: IProps) {
  const { handleEditModalVisible, isEditModalVisible } = useUser()
  const { user } = useLogged();
  const { updateUser } = useUser();

  const color = `#${user.company.primary_color}`;
  ConfigProvider.config({ theme: { primaryColor: color } });

  const handleUpdate = async (form: any) => {
    if (!form.password || !form.password.trim()) {
      delete form.password;
      delete form.confirmPassword;
    }

    const status: any = await updateUser(user.id, {
      ...user,
      ...form,
    });

    if (status?.status === 200) {
      return message.success("Editado!");
    }

    if (status?.response?.status == 406) {
      return message.warning("Não foi possivel completar a edição!");
    }

    message.error("Falha!");
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <LateralBar />
      <Layout className="children-container">
        <Header/>
        <Content className="site-layout-background">
          {
            children && (
              <Dashboard>
                { children }
              </Dashboard>
            )
          }
          <NewUserModal />
          <NewGroupsModal />
          <NewProjectModal />
          <NewModal
            buttonModalText={"Editar"}
            visible={isEditModalVisible}
            handleCancel={() => handleEditModalVisible(false)}
            handleOk={() => handleEditModalVisible(true)}
            title="Editar dados"
          >
            <FormEditModal
              name={user.name}
              email={user.email}
              onFinish={handleUpdate}
              onCancel={() => handleEditModalVisible(false)}
            />
          </NewModal>
        </Content>
      </Layout>
    </Layout>
  );
}
