import { AxiosError, AxiosResponse } from "axios";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { api } from "../services/api";
import { useLogged } from "./userLogin";

interface IProps {
  children: ReactNode;
}

interface IRolesContext {
  roles: IRole[];
  permissions: IPermissions[];
  loading: boolean;
  error: any | null;
  total: number;
  totalPages: number;
  isNewModalVisible: boolean;
  handleModalVisible: (visible: boolean) => void;
  fetchRoles: (
    page: number,
    order: string,
    orderBy: string,
    search?: string
  ) => Promise<void>;
  deleteRole: (id: string) => Promise<AxiosResponse | AxiosError>;
  createRole: (role: ICreateRoleDTO) => Promise<AxiosResponse | AxiosError>;
  updateRole: (
    id: string,
    body: ICreateRoleDTO
  ) => Promise<AxiosResponse | AxiosError>;
}

export interface IRole {
  id: string;
  name: string;
  description: string;
  company_id: string;
  company: {
    id: string;
    name: string;
    document: string;
    description: string;
    primary_color: string;
    secondary_color: string;
    logo: string;
    created_by: string;
  };
  permissions: IPermissions[];
}

interface IPermissions {
  id: string;
  name: string;
  alias: string;
  created_at: string;
  updated_at: string;
}

interface ICreateRoleDTO {
  description: string;
  name: string;
  permissionsIds: string[];
}

export const RolesContext = createContext<IRolesContext>({} as IRolesContext);

export const RolesStorage = ({ children }: IProps) => {
  const [total, setTotal] = useState(0);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [permissions, setPermissions] = useState<IPermissions[]>([]);
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isNewModalVisible, setINewModalVisible] = useState(false);

  const { token, user } = useLogged();

  async function handleModalVisible(visible: boolean) {
    setINewModalVisible(visible);
  }

  async function fetchData(type: string) {
    setLoading(true);
    setError(null);

    api
      .get(type == "roles" ? "/roles/list" : "/roles/permissions", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (type == "roles") setRoles(response.data.data);
        else setPermissions(response.data.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function createRole({
    description,
    name,
    permissionsIds,
  }: ICreateRoleDTO): Promise<AxiosResponse | AxiosError> {
    let response: AxiosResponse | AxiosError;

    try {
      setLoading(true);
      setError(null);

      response = await api.post(
        "/roles",
        {
          description,
          name,
          permissions: permissionsIds,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await fetchData("roles");
    } catch (err) {
      response = err as AxiosError;
      setError(err);
    } finally {
      setLoading(false);
    }

    return response;
  }

  async function deleteRole(id: string): Promise<AxiosResponse | AxiosError> {
    let response: AxiosResponse | AxiosError;

    try {
      setLoading(true);
      setError(null);

      response = await api.delete(`/roles/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      await fetchData("roles");
    } catch (err) {
      response = err as AxiosError;
      setError(err);
    } finally {
      setLoading(false);
    }

    return response;
  }

  async function updateRole(
    id: string,
    { description, name, permissionsIds }: ICreateRoleDTO
  ): Promise<AxiosResponse | AxiosError> {
    let response: AxiosResponse | AxiosError;

    setLoading(true);
    setError(null);

    try {
      setLoading(true);
      setError(null);

      response = await api.put(
        `/roles/${id}`,
        {
          description,
          name,
          permissions: permissionsIds,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (err) {
      response = err as AxiosError;
      setError(err);
    } finally {
      setLoading(false);
    }

    return response;
  }

  async function fetchRoles(
    page: number,
    order: string,
    orderBy: string,
    search?: string
  ) {
    setLoading(true);
    setError(null);

    api
      .get(
        search === undefined || !search
          ? `/roles/list?page=${page}&items=7&columnOrder=${orderBy}&order=${order}`
          : `/roles/list?page=${page}&items=7&columnOrder=${orderBy}&order=${order}&search=${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setRoles(response.data.data.roles);
        setTotal(response.data.data.rolesNumber);
        setTotalPages(response.data.data.totalPages);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchData("pemissions");
    fetchRoles(1, "asc", "name");
  }, []);

  return (
    <RolesContext.Provider
      value={{
        handleModalVisible,
        fetchRoles,
        createRole,
        deleteRole,
        updateRole,
        isNewModalVisible,
        totalPages,
        roles,
        total,
        permissions,
        error,
        loading,
      }}
    >
      {children}
    </RolesContext.Provider>
  );
};

export function useRoles() {
  const context = useContext(RolesContext);

  return context;
}
