import { AxiosError, AxiosResponse } from "axios";
import React, {
  Children,
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { api } from "../services/api";
import { useLogged } from "./userLogin";
import { IRole, useRoles } from "./useRoles";

interface IProps {
  children: ReactNode;
}

interface IUser {
  id: string;
  name: string;
  email: string;
  photo: string;
  role_id: string;
  company_id: string;
  created_at: string;
  updated_at: string;
  role: {
    id: string;
    name: string;
    description: string;
    company_id: string;
    created_at: string;
    updated_at: string;
  };
}

interface ICreateUserDTO {
  name: string;
  password?: string;
  email: string;
  role_id: string; 
}
interface IUsersContext {
  users: IUser[];
  loading: boolean;
  error: string | null;
  total: number;
  totalPages: number;
  isNewModalVisible: boolean;
  isEditModalVisible: boolean;
  roles: IRole[];
  handleModalVisible: (visible: boolean) => void;
  handleEditModalVisible: (visble: boolean) => void;
  fetchUsers: (
    page: number,
    order: string,
    orderBy: string,
    search?: string
  ) => Promise<void>;
  deleteUser: (id: string) => Promise<AxiosResponse | AxiosError>;
  createUser: (role: ICreateUserDTO) => Promise<AxiosResponse | AxiosError>;
  updateUser: (
    id: string,
    body: ICreateUserDTO
  ) => Promise<AxiosResponse | AxiosError>;
}

export const UsersContext = createContext<IUsersContext>({} as IUsersContext);

export const UsersStorage = ({ children }: IProps) => {
  const RolesContext = useRoles();
  const rolesTotal = RolesContext.total;
   
  const [users, setUsers] = useState<IUser[]>([]);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isNewModalVisible, setINewModalVisible] = useState(false);
  const [isEditModalVisible, setEditModalVisible] = useState(false);

  const { token, getUser } = useLogged();

  async function handleModalVisible(visible: boolean) {
    setINewModalVisible(visible);
  }

  async function handleEditModalVisible(visible :boolean){
    setEditModalVisible(visible);
  }

  async function fetchUsers(
    page: number,
    order: string,
    orderBy: string,
    search?: string
  ) {
    setLoading(true);
    setError(null);

    api
      .get(
        search === undefined || !search
          ? `/users?page=${page}&items=7&columnOrder=${orderBy}&order=${order}`
          : `/users?page=${
              page + 1
            }&items=7&columnOrder=${orderBy}&order=${order}&search=${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setUsers(response.data.data.users);
        setTotal(response.data.data.usersNumber);
        setTotalPages(response.data.data.totalPages);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function createUser({
    email,
    name,
    password,
    role_id,
  }: ICreateUserDTO): Promise<AxiosResponse | AxiosError> {
    let response: AxiosResponse | AxiosError;

    try {
      setLoading(true);
      setError(null);

      response = await api.post(
        "/users",
        {
          email,
          name,
          password,
          role_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await fetchUsers(1, "asc", "name");
    } catch (err) {
      response = err as AxiosError;
      setError(err);
    } finally {
      setLoading(false);
    }

    return response;
  }

  async function deleteUser(id: string): Promise<AxiosResponse | AxiosError> {
    let response: AxiosResponse | AxiosError;

    try {
      setLoading(true);
      setError(null);

      response = await api.delete(`/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      await fetchUsers(1, "asc", "name");
    } catch (err) {
      response = err as AxiosError;
      setError(err);
    } finally {
      setLoading(false);
    }

    return response;
  }

  async function updateUser(
    id: string,
    { email, name, role_id, password }: ICreateUserDTO
  ): Promise<AxiosResponse | AxiosError> {
    let response: AxiosResponse | AxiosError;

    setLoading(true);
    setError(null);

    try {
      setLoading(true);
      setError(null);

      response = await api.put(
        `/users/${id}`,
        {
          email,
          name,
          role_id,
          users,
          password,
          photo: "placeholder"

        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      await getUser(token!);
    } catch (err) {
      response = err as AxiosError;
      setError(err);
    } finally {
      setLoading(false);
    }

    return response;
  }

  useEffect(() => {
    async function fetchRoles() {
      api
        .get(
          `/roles/list?page=1&items=${rolesTotal}&columnOrder=name&order=asc`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setRoles(response.data.data.roles);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    fetchRoles();
  }, [users]);

  useEffect(() => {
    fetchUsers(1, "asc", "name");
  }, []);

  return (
    <UsersContext.Provider
      value={{
        users,
        error,
        loading,
        isNewModalVisible,
        isEditModalVisible,
        total,
        totalPages,
        roles,
        createUser,
        deleteUser,
        fetchUsers,
        handleModalVisible,
        handleEditModalVisible,
        updateUser,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export function useUser() {
  const context = useContext(UsersContext);

  return context;
}
