import axios from "axios";

// export const baseURL = 'http://localhost:3333'; //dev
// export const baseURL = 'http://54.82.119.75:3333'; //hml
export const baseURL = 'https://api.vconemanager.com.br'; //prod

export const api = axios.create({
  baseURL: baseURL,
});

api.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    if (response.status.toString() === "401") {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
