import { useUser } from "../../hooks/useUsers";
import { useEffect, useState } from "react"; 
import { useRoles } from "../../hooks/useRoles";

import { 
  SearchOutlined, 
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  message,
  Popconfirm,
  Select,
  Space,
  Table,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import FormItem from "antd/lib/form/FormItem";
import { Option } from "antd/lib/mentions";
import { NewModal } from "../NewModal";

interface IRowItem {
  name: string;
  role: string;
  email: string;
}

interface IEditUser {
  id: string;
  email: string;
  name: string;
  password?: string;
  role_id: string;
}

export function UserTable() {
  const { permissions } = useRoles();
  const {
    users,
    loading,
    fetchUsers,
    total,
    roles,
    updateUser,
    deleteUser,
    createUser,
    handleModalVisible,
    isNewModalVisible,
  } = useUser();
  const currentRoute = useParams();
  const navigate = useNavigate();

  const newUser =
    permissions.filter((item) => item.name === "newUser").length > 0;
  const editUser =
    permissions.filter((item) => item.name === "editUser").length > 0;

  const [visibleEdit, setVisibleEdit] = useState(false);
  const [page, setPage] = useState<number>(Number(currentRoute.page));
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState<string>("name");
  const [formDefault, setFormDefault] = useState<IEditUser>({} as IEditUser);
  const [selected, setSelected] = useState<IRowItem[]>([]);
  const [search, setSearch] = useState<string>("");
  const data = users.map((item) => {
    return {
      name: item.name,
      role: item.role.name,
      email: item.email,
    };
  });


  const handleOkEdit = async () => {
    const status: any = await updateUser(formDefault.id, {
      email: formDefault.email,
      name: formDefault.name,
      role_id: formDefault.role_id,
    });

    if (status?.status === 200) message.success("Editado");
    else message.error("Falha!");
  };

  const handleCancelEdit = () => {
    setVisibleEdit(false);
  };

  const confirm = async (record: IRowItem) => {
    const index = data.indexOf(record);

    const status: any = await deleteUser(users[index].id);

    if (status?.response?.status == 500 || status?.response?.status == 406) {
      message.warning("Falha!");
    } else {
      message.success("Excluído!");
    }

    fetchUsers(page, order, orderBy);
  };

  useEffect(() => {
    if (search != "") {
      fetchUsers(page, order, orderBy, search);
    } else {
      fetchUsers(page, order, orderBy);
    }
  }, [page]);
 
  function onChange(pagination: any, filters: any, sorter: any, extra: any) {
    if (sorter.field) setOrderBy(sorter.field);

    if (sorter.order === "descend") {
      if (search != "") {
        fetchUsers(page, "desc", sorter.field, search);
        setOrder("desc");
        setOrderBy(sorter.field ? sorter.field : "name");
      } else {
        fetchUsers(page, "desc", sorter.field);
        setOrder("desc");
        setOrderBy(sorter.field ? sorter.field : "name");
      }
    } else {
      if (search != "") {
        fetchUsers(page, "asc", sorter.field, search);
        setOrder("asc");
        setOrderBy(sorter.field ? sorter.field : "name");
      } else {
        fetchUsers(page, "asc", sorter.field);
        setOrder("asc");
        setOrderBy(sorter.field ? sorter.field : "name");
      }
    }
  }

  const FormEdit = ({ password, role_id, email, id, name }: IEditUser) => (
    <Form
      onFinish={async (form) => {
        setVisibleEdit(false);

        const status: any = await updateUser(id, form);

        if (status?.status === 200) message.success("Editado!");
        else if (status?.response?.status == 406)
          message.warning("Existem usuários nesse grupo!");
        else message.error("Falha!");

        fetchUsers(page, order, orderBy);
      }}
    >
      <Form.Item
        name="name"
        label="Nome"
        style={{
          marginBottom: "25px",
        }}
        rules={[
          {
            required: true,
            message: "Este campo é obrigatório!",
          },
        ]}
        initialValue={name}
      >
        <Input
          maxLength={50}
          placeholder="Nome do usuário!"
          defaultValue={name}
        />
      </Form.Item>
      <Form.Item
        name="email"
        label="E-mail"
        style={{
          marginBottom: "25px",
        }}
        rules={[
          {
            required: true,
            message: "Este campo é obrigatório!",
          },
        ]}
        initialValue={email}
      >
        <Input maxLength={50} placeholder="Email!" defaultValue={email} />
      </Form.Item>
      <FormItem
        name="role_id"
        label="Grupo"
        style={{
          marginBottom: "25px",
        }}
        rules={[
          {
            required: true,
            message: "Este campo é obrigatório!",
          },
        ]}
        initialValue={role_id}
      >
        <Select
          placeholder="Selecione o grupo!"
          defaultValue={role_id}
          onChange={() => {}}
          style={{ width: "100%" }}
        >
          {roles.map((item) => (
            <Option key={item.id}>{item.name}</Option>
          ))}
        </Select>
      </FormItem>
      <FormItem style={{ paddingLeft: "63%" }}>
        <Button type="primary" htmlType="submit">
          Salvar
        </Button>
        <Button onClick={() => setVisibleEdit(false)}>Cancelar</Button>
      </FormItem>
    </Form>
  );

 
  const columns: any = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      sorter: (a: IRowItem, b: IRowItem) => a.name.localeCompare(b.name),
      filterDropdown: () => (
        <div>
          <Input
            maxLength={50}
            placeholder="Buscar nome"
            onChange={({ target }) => setSearch(target.value)}
            style={{
              width: "9rem",
            }}
          />
          <Button
            type="primary"
            onClick={() => fetchUsers(page, order, orderBy, search)}
          >
            Buscar
          </Button>
        </div>
      ),

      filterIcon: (
        <SearchOutlined
          style={{
            fontSize: 17,
          }}
        />
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a: IRowItem, b: IRowItem) => a.name.localeCompare(b.name),
    },
    {
      title: "Grupo",
      dataIndex: "role",
      sorter: (a: IRowItem, b: IRowItem) => a.name.localeCompare(b.name),
      key: "role",
      onFilter: (value: any, record: any) => record.role.startsWith(value),
      filterSearch: true,
      width: "40%",
    },
  ];

  editUser &&
    columns.push({
      title: "Ações",
      key: "action",
      render: (text: string, record: IRowItem) => (
        <Space size="middle">
          <a
            style={{
              color: "#F7CD11",
            }}
            onClick={() => {
              const defaultValue = users.filter(
                (item) => item.email === record.email
              )[0];
              setFormDefault({
                email: defaultValue.email,
                name: defaultValue.name,
                role_id: defaultValue.role_id,
                id: defaultValue.id,
              });
              setVisibleEdit(true);
            }}
          >
            Editar
          </a>

          <Popconfirm
            title="Você tem certeza?"
            onConfirm={() => confirm(record)}
            okText="Sim"
            cancelText="Não"
          >
            <a
              style={{
                color: "#ff0000",
              }}
            >
              Deletar
            </a>
          </Popconfirm>
        </Space>
      ),
    });

  return (
    <div>
      {newUser && (
        <Space style={{ marginBottom: 16, marginTop: 15 }}>
          <Button onClick={() => handleModalVisible(true)}>Novo usuário</Button>
        </Space>
      )}
      <Table
        showSorterTooltip={{ title: "Clique para ordenar" }}
        dataSource={selected.length > 0 ? selected : data}
        columns={columns}
        rowKey={(item) => item.email}
        onChange={onChange}
        loading={loading}
        pagination={{
          position: ["bottomRight"],
          pageSize: 7,
          current: Number(page),
          total: total,
          onChange: (pageAux) => {
            navigate(`/users/${pageAux}`);
            setPage(pageAux);
          },
        }}
      />

     

      <NewModal
        buttonModalText={"Editar"}
        visible={visibleEdit}
        handleCancel={handleCancelEdit}
        handleOk={handleOkEdit}
        title="Editar grupo"
      >
        <FormEdit
          id={formDefault.id}
          email={formDefault.email}
          name={formDefault.name}
          role_id={formDefault.role_id}
        />
      </NewModal>
    </div>
  );
}
