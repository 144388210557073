import { useEffect, useState } from "react";

import { Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useNotifications } from "../../hooks/useNotifications";
import _ from "lodash";
import { CheckCircleOutlined, StopOutlined } from "@ant-design/icons";
import moment from "moment";

interface IRowItem {
  created_at: string;
}

export function Notification() {
  const currentRoute = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(Number(currentRoute.page));
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState<string>("created_at");

  const {
    fetchNotifications,
    notifications,
    updateNotification,
    loading,
    total
  } = useNotifications();

  
  useEffect(() => {
    const fetchNotificationsData = _.debounce(() => {
      fetchNotifications(page, order, orderBy);
    }, 500);

    fetchNotificationsData();
  }, [page, order, orderBy]);

  const handleTableChange = async (sorter: any) => {
    setOrderBy("created_at");

    if (sorter.order === "ascend") {
      fetchNotifications(page, "asc", orderBy);
      setOrder("asc");
    } else {
      fetchNotifications(page, "desc", orderBy);
      setOrder("desc");
    }
  }

  const columns: any = [
    {
      title: "Lida",
      dataIndex: "read",
      key: "read",
      render: (read: boolean, record: any) => (
        <div
          onClick={() => updateNotification(record.id, !read)}
          style={{ cursor: "pointer" }}
        >
          {read ? <CheckCircleOutlined /> : <StopOutlined />}
        </div>
      ),
    },
    {
      title: "Mensagem",
      dataIndex: "message",
      key: "message",
      render: (message: string) => (
        <div dangerouslySetInnerHTML={{__html: message}}>

        </div>
      ),
    },
    {
      title: "Data",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a: IRowItem, b: IRowItem) => a.created_at.localeCompare(b.created_at),
      render: (created_at: string) => (
        <div>
          {moment(created_at).format('DD/MM/YYYY HH:mm:ss')}
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        dataSource={notifications}
        columns={columns}
        rowKey={(item) => item.id}
        loading={loading}
        onChange={handleTableChange}
        pagination={{
          position: ["bottomRight"],
          pageSize: 7,
          current: Number(page),
          total: total,
          onChange: (pageAux) => {
            setPage(pageAux);
            navigate(`/notifications/${pageAux}`);
          },
        }}
      />
    </div>
  )
}