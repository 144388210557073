import { Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Login } from "../pages/Login";

export function AuthRoutes() {
  return (
    <>
      <Helmet>
        <title>Gestão</title>‍
        <meta name="description" content="" />
        <link
          rel="icon"
          href="https://logosmarcas.net/wp-content/uploads/2020/04/Nissan-Logo.png"
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<Login />} />
      </Routes>
    </>
  );
}
