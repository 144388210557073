import { useEffect, useRef, useState } from "react";
import moment, { Moment } from "moment";
import momentBusinessDays from "moment-business-days";
import {
  Input,
  Card,
  Select,
  Tooltip,
  Tabs,
  message,
  Button,
  Modal,
  DatePicker,
  Col,
  Form,
  Upload,
  Popconfirm,
} from "antd";
import {
  CalendarOutlined,
  ExclamationCircleOutlined,
  FlagOutlined,
  LinkOutlined,
  LoadingOutlined,
  PlusOutlined,
  SaveOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { api } from "../../services/api";
import {
  ContentHeader,
  Kanban,
  Phase,
  TasksContainer,
  Task,
  Loading,
  ModalTaskBody,
  DisplayInRow,
  HorizontalSpace,
  DisplayInColumn,
  VerticalSpace
} from './styles';
import { useLogged } from '../../hooks/userLogin';
import { useRoles } from '../../hooks/useRoles';

import UserAvatar from "../UserAvatar";
import { UploadFile } from "antd/lib/upload/interface";
import { MentionsInput, Mention } from 'react-mentions';
import { useNavigate } from "react-router-dom";

const statuses = [
  { id: 0, title: 'Não iniciada' },
  { id: 1, title: 'Em Progresso' },
  { id: 2, title: 'Finalizada' }
];

interface IUser {
  id: string;
  name: string;
  email: string;
  photo: string;
  role_id: string;
  company_id: any;
}

interface ITaskComment {
  id: string;
  comment: string;
  task_id: string;
  user_id: string;
  created_at: string;
  updated_at: string;
  user: IUser;
}

interface ITaskHistory {
  id: string;
  key: string;
  old_value: string;
  new_value: string;
  motive?: string;

  user: IUser;

  created_at: string;
  updated_at: string;
}

interface ITaskAttachment {
  id: string;
  filename: string;
  url: string;

  user: IUser;

  created_at: string;
  updated_at: string;
}

interface ITask {
  id: string;
  title: string;
  description: string;
  days: string;
  duration: any;
  phase_id: any;
  parent_id: any;
  plan_init_date: string;
  plan_finish_date: string;
  result_init_date: string;
  result_finish_date: string;

  assign_id?: string;
  assign_at?: string;
  assign_user?: IUser;

  status_id: number;
  status_at?: string;

  active: number;

  tasks?: Array<ITask>;
  comments?: Array<ITaskComment>;
  histories?: Array<ITaskHistory>;
  attachments?: Array<ITaskAttachment>;

  created_at: string;
  updated_at: string;
}

interface IPhase {
  id: string;
  title: string;
  description: string;
  index: number;
  project_id: string;
  plan_init_date: string;
  plan_finish_date: any;
  result_init_date: string;
  result_finish_date: string;
  tasks: Array<ITask>;
}

interface IProject {
  id: string;
  title: string;
  description: string;
  date_init: any;
  template_id: string;
  phases: Array<IPhase>;
  user_responsable_id?: string;
}

interface ITasksProp {
  tasks: Array<ITask>;
  onClick: (task: ITask) => void;
}
const Tasks = ({ tasks, onClick }: ITasksProp) => {
  return (
    <>
      {
        tasks.map((task: ITask) => (
          <>
            <Task
              key={`${task.parent_id}_${task.id}`}
              onClick={() => onClick(task)}
              className="task"
              style={{
                borderColor: task.active && task.status_id === 1 && moment(task.plan_init_date).isBefore(moment())
                  ? '#faad14'
                  : (
                    task.active && task.status_id !== 2 && moment(task.plan_finish_date).isBefore(moment())
                      ? '#ff4d4f'
                      : 'transparent'
                  ),
                backgroundColor: !task.active ? '#dddddd' : '#ffffff',
              }}
            >
              <p className="title">
                {
                  (task.attachments && task.attachments.length > 0) && (
                    <Tooltip placement="top" title="Possui anexos">
                      <LinkOutlined />
                      {' '}
                    </Tooltip>
                  )
                }
                {task.title}
              </p>
              <div className="task_info">
                <Tooltip placement="top" title="Atruibuido à">
                  <label><UserOutlined /> {task.assign_user?.name}</label>
                </Tooltip>
                <Tooltip placement="top" title="Status">
                  <label>
                    <FlagOutlined />
                    {' ' + (!task.active ? 'Desativada' : statuses.find(st => st.id === task.status_id)?.title)}
                  </label>
                </Tooltip>
              </div>
              <div className="task_plan">
                <Tooltip placement="top" title="Data de início planejado">
                  <label><CalendarOutlined /> {moment(task.result_init_date.split('T')[0]).format('DD/MM/YYYY')}</label>
                </Tooltip>
                <Tooltip placement="top" title="Data de encerramento planejado">
                  <label><CalendarOutlined /> {moment(task.result_finish_date.split('T')[0]).format('DD/MM/YYYY')}</label>
                </Tooltip>
              </div>
            </Task>
            {
              !!task.tasks && <Tasks tasks={task.tasks} onClick={onClick} />
            }
          </>
        ))
      }
    </>
  );
}

interface ICommentProp {
  comment: ITaskComment;
  handleEdit: (id: string, text: string) => Promise<boolean>;
}

const Comment = ({ comment, handleEdit }: ICommentProp) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [text, setText] = useState<string>(comment.comment);
  const userLogged = useLogged();

  const handleSave = async () => {
    const success = await handleEdit(comment.id, text);
    setIsEditing(!success);
  }

  useEffect(() => {
    const newComment = comment.comment.replace(/\@\[(.*?)\]/g, "@$1").replace(/\([^)]+\)/g, "");
    setText(newComment);
  }, []);

  return (
    <div className="comment">
      <UserAvatar userName={comment.user.name} />
      <div className="comment_content">
        <p>
          <strong>{comment.user.name}</strong> {moment(comment.updated_at).fromNow()}
          .
          {
            comment.user_id === userLogged.user.id && isEditing && <Button type="link" onClick={() => setIsEditing(false)}><small>Cancelar Edição</small></Button>
          }{
            comment.user_id === userLogged.user.id && !isEditing && <Button type="link" onClick={() => setIsEditing(true)}><small>Editar {comment.created_at !== comment.updated_at ? '(Editado)' : ''}</small></Button>
          }
        </p>
        {
          isEditing
            ? (
              <Input
                value={text}
                onChange={(event: any) => setText(event.target.value)}
                onPressEnter={handleSave}
                maxLength={250}
                autoFocus
              />
            )
            : (
              <div>
                {text}
              </div>
            )
        }
      </div>
    </div>
  )
}

interface IHistoryProp {
  history: ITaskHistory;
  users: IUser[];
}

const History = ({ history, users }: IHistoryProp) => {
  const keyMap = [
    { key: 'status_id', value: 'Status' },
    { key: 'assign_id', value: 'Atribuido à' },
    { key: 'active', value: 'Ativa' },
    { key: 'result_init_date', value: 'Data de início' },
    { key: 'result_finish_date', value: 'Data de encerramento' },
    { key: 'comment', value: 'comentário' },
  ];

  let initialMessage: string = 'Alterou o campo';
  let old_value: string = history.old_value;
  let new_value: string = history.new_value;
  let motive: string | undefined = history.motive;

  if (history.key === 'status_id') {
    old_value = statuses.find(st => st.id === Number(old_value))?.title || '...';
    new_value = statuses.find(st => st.id === Number(new_value))?.title || '...';
  }

  if (history.key === 'assign_id') {
    old_value = users.find(st => st.id === old_value)?.name || '...';
    new_value = users.find(st => st.id === new_value)?.name || '...';
  }

  if (history.key === 'active') {
    old_value = old_value === '1' ? 'Sim' : 'Não';
    new_value = new_value === '1' ? 'Sim' : 'Não';
    motive = motive ? motive : '';
  }

  if (history.key === 'result_init_date') {
    old_value = old_value ? moment(old_value.split('T')[0]).format('DD/MM/YYYY') : '...';
    new_value = new_value ? moment(new_value.split('T')[0]).format('DD/MM/YYYY') : '...';
  }

  if (history.key === 'result_finish_date') {
    old_value = old_value ? moment(old_value.split('T')[0]).format('DD/MM/YYYY') : '...';
    new_value = new_value ? moment(new_value.split('T')[0]).format('DD/MM/YYYY') : '...';
  }

  if (history.key === 'comment') {
    initialMessage = 'Alterou um';
    motive = motive ? motive : '';
  }

  return (
    <div className="history">
      <UserAvatar userName={history.user.name} />
      <div className="history_content">
        <p><strong>{history.user.name}</strong> {moment(history.created_at).fromNow()}</p>
        <div>
          <p>{initialMessage} <strong>{keyMap.find(item => item.key === history.key)?.value}</strong> de <strong>{old_value}</strong> para <strong>{new_value}</strong></p>
          {
            !!motive && (
              <p>Motivo: <strong>{motive}</strong></p>
            )
          }
        </div>
      </div>
    </div>
  )
}
interface IAttachmentPreviewProp {
  url: string;
}

const AttachmentPreview = ({ url }: IAttachmentPreviewProp) => {
  const extImage = ['png', 'jpg', 'jpeg'];
  const extAttachment = url.split('.').pop();

  if (!extImage.includes(extAttachment?.toLowerCase() as string)) {
    return (
      <div className="attachment_preview_ext">
        {extAttachment}
      </div>
    );
  }

  return (
    <img src={url} className="attachment_preview_img" />
  )
}

interface IAttachmentProp {
  attachment: ITaskAttachment;
  onRemove: () => void
}

const Attachment = ({ attachment, onRemove }: IAttachmentProp) => {
  return (
    <div className="attachment">
      <AttachmentPreview url={attachment.url} />
      <div className="attachment_content">
        <p>
          <a href={attachment.url} target="_blank">
            <strong>{attachment.filename}</strong>
          </a>
        </p>
        <p>
          Adicionado: {moment(attachment.created_at).format('DD/MM/YYYY HH:mm:ss')} -
          <Popconfirm
            title="Deseja realmente excluir?"
            onConfirm={onRemove}
            okText="Sim"
            cancelText="Não"
          >
            <Button type="link">Excluir</Button>
          </Popconfirm>
        </p>
      </div>
    </div>
  )
}

const initialNewTask: ITask = {
  title: '',
  description: '',
  days: 'working_days',
  duration: 1,
  phase_id: '',
  plan_init_date: '',
  status_id: 0,
  active: 1,
} as ITask;

export function ProjectDetails() {
  const [isLoading, setLoading] = useState(false);
  const [isModalLoading, setModalLoading] = useState(false);
  const [project, setProject] = useState<IProject>();
  const [users, setUsers] = useState<Array<IUser>>();

  const [canEditAssign, setCanEditAssign] = useState(false);
  const [canEditStatus, setCanEditStatus] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showTaskDisableModal, setShowTaskDisableModal] = useState(false);
  const [showAddTaskModal, setAddShowTaskModal] = useState(false);
  const [currentTask, setCurrentTask] = useState<ITask>({} as ITask);
  const [commentValue, setCommentValue] = useState<string>('');
  const [taskDisableMotive, setTaskDisableMotive] = useState<string>('');
  const [newTask, setNewTask] = useState<ITask>(initialNewTask);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);

  const currentRoute = useParams();
  const { taskId } = useParams();
  const userLogged = useLogged();
  const roles = useRoles();

  const user = userLogged.user;
  const canEditProject = roles.roles
    .find(role => role.id === user.role_id)?.permissions
    .find(permission => permission.name === 'editProject');

  const [formNewTask] = Form.useForm();

  const uploadRef = useRef<UploadFile | null>(null);

  const navigate = useNavigate();

  const fetchProject = async (id: string) => {
    try {
      setLoading(true);
      const { data } = await api.get(`/projects/${id}`);
      setProject(data.data);
    } catch (error: any) {
      message.error('Erro ao carregar projeto');
    } finally {
      setLoading(false);
    }
  }

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`/users`);
      setUsers(data.data.users);
    } catch (error: any) {
      message.error('Erro ao buscar usuários');
    } finally {
      setLoading(false);
    }
  }

  const handleTaskClick = async (task: ITask) => {
    try {
      setCanEditAssign(!!canEditProject);
      setCommentValue('');
      setCanEditStatus(task.assign_id === user.id || !!canEditProject);

      const { data } = await api.get(`/projects/${project?.id}/task/${task.id}`);

      setCurrentTask(data.data);

      const comment_container = document.querySelector('.comment_container');
      comment_container?.scrollTo({ top: 0, behavior: 'smooth' });

      const histories_container = document.querySelector('.histories_container');
      histories_container?.scrollTo({ top: 0, behavior: 'smooth' });

      setShowTaskModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const findTaskById = (tasks: ITask[], taskId: string): ITask | null => {
    for (const task of tasks) {
      if (task.id === taskId) {
        return task;
      }

      if (task.tasks && task.tasks?.length > 0) {
        const nestedTask = findTaskById(task.tasks, taskId);
        if (nestedTask) {
          return nestedTask;
        }
      }
    }

    return null;
  };


  useEffect(() => {
    if (taskId !== undefined && project) {
      const openTask = project.phases
        .map((phase) => findTaskById(phase.tasks, taskId))
        .filter((task) => task !== null)
        .pop();

      if (openTask) {
        handleTaskClick(openTask);
      }
    }
  }, [taskId, project]);


  const handleAssignUser = async (userId: string) => {
    try {
      setModalLoading(true);
      const body = { user_id: userId };
      const { data } = await api.post(`/projects/${project?.id}/task-assign/${currentTask.id}`, body);
      setCurrentTask({
        ...currentTask,
        assign_id: userId,
        histories: data.data.histories
      });
    } catch (error: any) {
      message.error('Erro ao atribuir usuário');
    } finally {
      setModalLoading(false);
    }
  };

  const handleStatus = async (status_id: number) => {
    if (!canEditAssign && currentTask.status_id > status_id) {
      message.warn('Não é possivel voltar para um status anterior');
      return;
    }

    if (status_id === 2) {
      const { confirm } = Modal;
      confirm({
        title: 'Deseja realmente finalizar a tarefa nesta data?',
        icon: <ExclamationCircleOutlined />,
        content: `Data: ${moment(currentTask.result_finish_date.split('T')[0]).format('DD/MM/YYYY')}`,
        onOk() {
          updateStatus(status_id)
        },
        onCancel() {
          return
        },
      });
      return;
    }

    updateStatus(status_id);
  }

  const updateStatus = async (status_id: number) => {
    try {
      setModalLoading(true);
      const body = { status_id };
      const { data } = await api.post(`/projects/${project?.id}/task-status/${currentTask.id}`, body);
      setCurrentTask({
        ...currentTask,
        result_init_date: data.data.result_init_date,
        result_finish_date: data.data.result_finish_date,
        status_id,
        assign_user: data.data.assign_user,
        histories: data.data.histories
      });
    } catch (error: any) {
      message.error('Erro ao definir status');
    } finally {
      setModalLoading(false);
    }
  }

  const handleAddComment = async (event: any) => {
    try {
      setModalLoading(true);
      const body = { user_id: userLogged.user.id, comment: commentValue };
      const { data } = await api.post(`/projects/${project?.id}/task-comment/${currentTask.id}`, body);
      setCurrentTask({
        ...currentTask,
        comments: data.data.comments
      });
      setCommentValue('')
    } catch (error: any) {
      message.error('Erro ao comentar');
    } finally {
      setModalLoading(false);
    }
  }

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddComment(e);
    }
  };

  const handleEditComment = async (id: string, text: any) => {
    try {
      setModalLoading(true);
      const { data } = await api.post(`/projects/${project?.id}/task-comment/${currentTask.id}/edit/${id}`, {
        comment: text
      });
      setCurrentTask({
        ...currentTask,
        comments: data.data.comments,
        histories: data.data.histories,
      });
      return true;
    } catch (error: any) {
      message.error('Erro editar comentário');
      return false;
    } finally {
      setModalLoading(false);
    }
  }

  const handleShowTaskDisableModal = () => {
    setShowTaskDisableModal(true);
    setTaskDisableMotive('');
  }

  const handleDisableTask = async () => {
    if (!canEditAssign) {
      message.warn('Você não pode desativar essa tarefa.');
      return;
    }

    if (currentTask.status_id == 2) {
      message.warn('Você não pode desativar uma tarefa concluida tarefa.');
      return;
    }

    try {
      setModalLoading(true);
      const body = { motive: taskDisableMotive };
      const { data } = await api.post(`/projects/${project?.id}/task-disable/${currentTask.id}`, body);
      setCurrentTask({
        ...currentTask,
        active: data.data.active,
        histories: data.data.histories
      });
      setShowTaskDisableModal(false);
    } catch (error) {
      message.error('Erro ao desativar tarefa');
    } finally {
      setModalLoading(false);
    }
  }

  const updateTaskDate = async (field: any, value: Moment, recalculate: boolean) => {
    if (!value) return;

    if (currentTask.days == 'working_days' && !momentBusinessDays(value).isBusinessDay()) {
      message.warn('Essa tarefa não pode ser iniciada/finalizada em finais de semana.');
      return;
    }

    try {
      setModalLoading(true);
      const body = {
        field,
        value: `${value.format('YYYY-MM-DD')}T00:00:00.000+00:00`,
        recalculate
      };
      const { data } = await api.post(`/projects/${project?.id}/task-update-dates/${currentTask.id}`, body);
      setCurrentTask({
        ...currentTask,
        plan_finish_date: data.data.plan_finish_date,
        result_init_date: data.data.result_init_date,
        result_finish_date: data.data.result_finish_date,
        histories: data.data.histories,
      });
    } catch (error) {
      message.error('Erro ao alterar data da tarefa');
    } finally {
      setModalLoading(false);
    }
  }

  const handleProjectResponsable = async (userId: string) => {
    try {
      setLoading(true);
      const body = { user_id: userId };
      const { data } = await api.post(`/projects/${project?.id}/set-responsable`, body);
      setProject({
        ...project,
        user_responsable_id: data.data.user_responsable_id
      } as IProject)
    } catch (error: any) {
      message.error('Erro ao definir responsável');
    } finally {
      setLoading(false);
    }
  }

  const handleOpenAddTask = async (phase_id: string) => {
    const phase = project?.phases.find(phase => phase.id == phase_id);
    const plan_init_date = phase?.result_finish_date || phase?.plan_finish_date;
    setAddShowTaskModal(true);
    setNewTask({
      title: initialNewTask.title,
      description: initialNewTask.description,
      days: initialNewTask.days,
      duration: initialNewTask.duration,
      status_id: initialNewTask.status_id,
      active: initialNewTask.active,
      // plan_init_date,
      phase_id,
    } as ITask);
    formNewTask.resetFields();
  }

  const handleAddTask = async (form: any) => {
    try {
      setModalLoading(true);
      const body = {
        ...newTask,
        ...form
      };
      await api.post(`/projects/${project?.id}/add-task`, body);
      setAddShowTaskModal(false);
      fetchProject(project?.id as string);
    } catch (error: any) {
      message.error('Erro ao adicionar tarefa');
    } finally {
      setModalLoading(false);
    }
  }

  const handleUploadAttachment = async (event: any) => {
    try {
      setModalLoading(true);
      const formData = new FormData();

      event.fileList.forEach((file: any) => {
        formData.append("files", file.originFileObj);
        formData.append("filenames", file.name);
      });

      const { data } = await api.post(`/projects/${project?.id}/task-attachment/${currentTask.id}`, formData);
      setCurrentTask({
        ...currentTask,
        attachments: data.data.attachments
      });
    } catch (error: any) {
      message.error('Erro ao adicionar anexos');
    } finally {
      setModalLoading(false);
    }
  }

  const handleFileSelection = (event: any) => {
    const filesToAdd = event.fileList;

    const newFiles = Array.from(filesToAdd).filter((file: any) => {
      return !selectedFiles.some((selectedFile: any) => selectedFile.name === file.name);
    });

    setSelectedFiles([...selectedFiles, ...newFiles]);
  };

  const handleSaveFiles = async () => {
    setModalLoading(true);
    const formData = new FormData();


    selectedFiles.forEach((file: any) => {
      formData.append("files", file.originFileObj);
    });

    try {
      const { data } = await api.post(`/projects/${project?.id}/task-attachment/${currentTask.id}`, formData);
      setCurrentTask({
        ...currentTask,
        attachments: data.data[0].attachments
      });

      setSelectedFiles([]);
    } catch (error) {
      message.error('Erro ao adicionar anexos');
    } finally {
      setModalLoading(false);
    }
  };


  const handleRemoveAttachment = async (attachment_id: string) => {
    try {
      setModalLoading(true);
      await api.delete(`/projects/${project?.id}/task-attachment/${attachment_id}`);
      setCurrentTask({
        ...currentTask,
        attachments: currentTask.attachments?.filter(attachment => attachment.id !== attachment_id),
      })
    } catch (error: any) {
      message.error('Erro ao remover anexo');
    } finally {
      setModalLoading(false);
    }
  }

  useEffect(() => {
    fetchProject(currentRoute.id as string);
    fetchUsers();
  }, []);

  let mentionData: any = [];

  mentionData = users?.map((userData) => ({
    id: userData.id,
    display: userData.name,
  }));

  return (
    <>
      <ContentHeader>
        <h3>{project?.title}</h3>
        <Select
          showSearch
          placeholder="Responsável pelo projeto"
          optionFilterProp="children"
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA: any, optionB: any) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }
          value={project?.user_responsable_id}
          onChange={handleProjectResponsable}
          disabled={isLoading || !canEditProject}
          style={{ width: '300px' }}
        >
          {
            users?.map(user => (
              <Select.Option value={user.id}>{user.name}</Select.Option>
            ))
          }
        </Select>
      </ContentHeader>

      <Kanban>
        {
          !isLoading && project && project.phases.sort((a, b) => a.index - b.index).map(phase => (
            <Phase>
              <div className="title-container">
                <p className="title">{phase.title}</p>
                <Tooltip title="Adicionar tarefa">
                  <Button
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={() => handleOpenAddTask(phase.id)}
                  />
                </Tooltip>
              </div>

              <TasksContainer>
                <Tasks tasks={phase.tasks} onClick={handleTaskClick} />
              </TasksContainer>
            </Phase>
          ))
        }
        {
          isLoading && (
            <Loading>
              <LoadingOutlined style={{ fontSize: '2rem' }} />
            </Loading>
          )
        }
      </Kanban>

      <Modal
        title={currentTask.title}
        visible={showTaskModal}
        footer={null}
        width='60%'
        style={{ top: 20 }}
        centered={false}
        bodyStyle={{
          padding: '10px'
        }}
        onCancel={() => {
          if (!isModalLoading) {
            setShowTaskModal(false);
            fetchProject(currentRoute.id as string);
            navigate(`/project-details/${currentRoute.id}`);
          }
        }}
      >
        <ModalTaskBody>
          <div className="task_description">
            {currentTask.description}
          </div>

          <DisplayInRow>

            <DisplayInColumn style={{ width: '45%' }}>
              <div className="task_plan">
                <Card title="Previsão">
                  <Col>
                    <label><CalendarOutlined /> Data de início:</label>
                    <br />
                    <DatePicker
                      value={currentTask.plan_init_date ? moment(currentTask.plan_init_date.split('T')[0]) : null}
                      format='DD/MM/YYYY'
                      disabled
                    />
                  </Col>
                  <Col>
                    <label><CalendarOutlined /> Data de encerramento:</label>
                    <br />
                    <DatePicker
                      value={currentTask.plan_finish_date ? moment(currentTask.plan_finish_date.split('T')[0]) : null}
                      format='DD/MM/YYYY'
                      onChange={(event: any) => updateTaskDate('plan_finish_date', event, true)}
                      disabled={isModalLoading || currentTask && currentTask.status_id == 2}
                    />
                  </Col>
                </Card>
              </div>

              <VerticalSpace />

              <div className="task_executed">
                <Card title="Realizado">
                  <Col>
                    <label><CalendarOutlined /> Data de início:</label>
                    <br />
                    <DatePicker
                      value={currentTask.result_init_date ? moment(currentTask.result_init_date.split('T')[0]) : null}
                      format='DD/MM/YYYY'
                      onChange={(event: any) => updateTaskDate('result_init_date', event, currentTask.status_id != 2)}
                      disabled={isModalLoading}
                    />
                  </Col>
                  <Col>
                    <label><CalendarOutlined /> Data de encerramento:</label>
                    <br />
                    <DatePicker
                      value={currentTask.result_finish_date ? moment(currentTask.result_finish_date.split('T')[0]) : null}
                      format='DD/MM/YYYY'
                      onChange={(event: any) => updateTaskDate('result_finish_date', event, currentTask.status_id != 2)}
                      disabled={isModalLoading}
                    />
                  </Col>
                </Card>
              </div>
            </DisplayInColumn>

            <HorizontalSpace />

            <DisplayInColumn style={{ flex: 1, minHeight: '210px', justifyContent: 'flex-start' }}>
              <div className="task_status">
                <label>Status:</label>
                {
                  canEditStatus && currentTask.assign_id && currentTask.status_id !== 2
                    ? (
                      <Select
                        placeholder="Selecione um status"
                        value={currentTask.status_id}
                        onChange={handleStatus}
                        disabled={isModalLoading}
                      >
                        {
                          statuses?.map(status => (
                            <Select.Option
                              value={status.id}
                            >
                              {status.title}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    )
                    : (
                      <Input
                        disabled
                        value={statuses.find(st => st.id === currentTask.status_id)?.title}
                      />
                    )
                }
              </div>

              <div className="task_assigned_user">
                <label>Atruibuido à:</label>
                {
                  canEditAssign && currentTask.status_id !== 2
                    ? (
                      <Select
                        showSearch
                        placeholder="Selecione um usuário"
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA: any, optionB: any) =>
                          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                        value={currentTask.assign_id}
                        onChange={handleAssignUser}
                        disabled={isModalLoading}
                      >
                        {
                          users?.map(user => (
                            <Select.Option value={user.id}>{user.name}</Select.Option>
                          ))
                        }
                      </Select>
                    )
                    : (
                      <Input
                        disabled
                        value={currentTask.assign_user?.name}
                      />
                    )
                }
              </div>

              <div className="task_options">
                {
                  canEditAssign && currentTask.status_id !== 2 && (
                    <>
                      <label>&nbsp;</label>
                      <Button
                        onClick={handleShowTaskDisableModal}
                        disabled={isModalLoading || currentTask.active == 0}
                      >
                        Desativar tarefa
                      </Button>
                    </>
                  )
                }
              </div>
            </DisplayInColumn>

          </DisplayInRow>

          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Comentários" key="comments">
              <div className="task_comments">
                {
                  canEditStatus && (
                    <div className="add_comment">
                      <UserAvatar />
                      <MentionsInput
                        value={commentValue}
                        onChange={(e) => setCommentValue(e.target.value)}
                        maxLength={250}
                        style={{ width: '100%' }}
                        onKeyDown={handleKeyPress}
                      >
                        <Mention
                          trigger="@"
                          data={mentionData}
                          displayTransform={(id, display) => `@${display}`}
                        />
                      </MentionsInput>
                      {/* <Input
                        value={commentValue}
                        onChange={(event: any) => setCommentValue(event.target.value)}
                        onPressEnter={handleAddComment}
                        maxLength={250}
                      /> */}
                    </div>
                  )
                }

                <div className="comment_container">
                  {
                    !!currentTask.comments && currentTask.comments.map(comment => (
                      <Comment
                        comment={comment}
                        handleEdit={handleEditComment}
                      />
                    ))
                  }
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Anexos" key="attachments">
              <div className="task_attachments">
                {
                  (canEditStatus || userLogged.user.id == currentTask.assign_id) && (
                    <div className="add_attachment">
                      {selectedFiles.length == 0 && (
                        <Upload
                          ref={uploadRef}
                          onChange={handleFileSelection}
                          beforeUpload={() => false}
                          showUploadList={false}
                          fileList={[]}
                          multiple={true}
                        >
                          <Button
                            loading={isModalLoading}
                            icon={<UploadOutlined />}
                          >
                            Adicionar
                          </Button>
                        </Upload>
                      )}
                      {selectedFiles.length > 0 && (
                        <div>
                          <h3>Arquivos Selecionados:</h3>
                          <ul>
                            {selectedFiles.map((file: any, index: number) => (
                              <li key={index}>{file.name}</li>
                            ))}
                          </ul>
                          <Button
                            onClick={handleSaveFiles}
                            disabled={selectedFiles.length === 0 || isModalLoading}
                            icon={<SaveOutlined />}
                          >
                            Salvar arquivos
                          </Button>
                        </div>
                      )}
                    </div>
                  )
                }

                <div className="attachment_container">
                  {
                    !!currentTask.attachments && currentTask.attachments.map(attachment => (
                      <Attachment
                        attachment={attachment}
                        onRemove={() => handleRemoveAttachment(attachment.id)}
                      />
                    ))
                  }
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Alterações" key="histories">
              <div className="histories_container">
                {
                  !!currentTask.histories && currentTask.histories.sort((a, b) => a.created_at >= b.created_at ? -1 : 1).map(history => (
                    <History history={history} users={users || []} />
                  ))
                }
              </div>
            </Tabs.TabPane>
          </Tabs>
        </ModalTaskBody>
      </Modal>

      <Modal
        title={`Desativar tarefa: ${currentTask.title}`}
        visible={showTaskDisableModal}
        onOk={handleDisableTask}
        confirmLoading={isModalLoading}
        onCancel={() => setShowTaskDisableModal(false)}
      >
        <p>Essa ação não podera ser desfeita!</p>
        <p>Tem certeza que deseja desativar essa tarefa?</p>
        <Input
          placeholder="Motivo da desativação (optional)"
          maxLength={255}
          value={taskDisableMotive}
          onChange={(event) => setTaskDisableMotive(event.target.value)}
        />
      </Modal>

      <Modal
        title="Adicionar tarefa"
        visible={showAddTaskModal}
        confirmLoading={isModalLoading}
        onOk={() => formNewTask.submit()}
        onCancel={() => setAddShowTaskModal(false)}
      >
        <Form
          form={formNewTask}
          initialValues={newTask}
          onFinish={handleAddTask}
        >
          <Form.Item
            name="title"
            label="Nome"
            style={{
              marginBottom: "25px",
            }}
            rules={[
              {
                required: true,
                message: "Este campo é obrigatório!",
              },
            ]}
          >
            <Input
              maxLength={50}
              placeholder="Nome da tarefa"
            />
          </Form.Item>

          <Form.Item
            name="description"
            label="Descrição"
            style={{
              marginBottom: "25px",
            }}
            rules={[
              {
                required: true,
                message: "Este campo é obrigatório!",
              },
            ]}
          >
            <Input
              maxLength={50}
              placeholder="Descrição da tarefa"
            />
          </Form.Item>

          <Form.Item
            name="duration"
            label="Duração da tarefa"
            style={{
              marginBottom: "25px",
            }}
            rules={[
              {
                required: true,
                message: "Este campo é obrigatório!",
              },
            ]}
          >
            <Input
              type="number"
              min={1}
              step={1}
              placeholder="Duração da tarefa"
            />
          </Form.Item>

          <Form.Item
            name="days"
            label="Dias contáveis"
            style={{
              marginBottom: "25px",
            }}
            rules={[
              {
                required: true,
                message: "Este campo é obrigatório!",
              },
            ]}
          >
            <Select
              placeholder="Selecione os dias contáveis"
              style={{ width: "100%" }}
            >
              <Select.Option value="working_days">Dias úteis</Select.Option>
              <Select.Option value="calendar_days">Dias corridos</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="plan_init_date"
            label="Data de início"
            style={{
              marginBottom: "25px",
            }}
            rules={[
              {
                required: true,
                message: "Este campo é obrigatório!",
              },
            ]}
          >
            <DatePicker
              placeholder="Selecione uma data"
              format="DD/MM/YYYY"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
