import styled from "styled-components";
import { Button } from "antd";
import Form from "antd/lib/form/Form";

interface IProps {
  isItem: "template" | "step" | "task";
}

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.shape};

  min-height: 50vh;
`;

export const Item = styled.div<IProps>`
  display: flex;
  flex-direction: row;

  align-items: center;

  margin-bottom: 1rem;
  margin-top: 1rem;

  outline: none;

  background-color: inherit;

  :hover {
    color: inherit !important;
  }
`;

export const Initial = styled.div<IProps>`
  font-size: ${({ isItem }) => (isItem === "template" ? 1.5 : 1.25)}rem;

  background-color: transparent;

  color: ${({ theme }) => theme.colors.shape};
  background-color: ${({ theme, isItem }) => {
    if (isItem === "template") return theme.colors.success_light;
    if (isItem === "step") return theme.colors.secondary;
    if (isItem === "task") return theme.colors.attention;
  }};

  height: ${({ isItem }) => {
    if (isItem === "template") return 2.5;
    if (isItem === "step") return 2;
    if (isItem === "task") return 1.6;
  }}rem;
  width: ${({ isItem }) => {
    if (isItem === "template") return 2.5;
    if (isItem === "step") return 2;
    if (isItem === "task") return 1.6;
  }}rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 2rem;
`;

export const Text = styled.div<IProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;

  font-weight: bold;
  font-size: ${({ isItem }) => {
    if (isItem === "template") return 1.4;
    if (isItem === "step") return 1.2;
    if (isItem === "task") return 1;
  }}rem;

  color: ${({ theme }) => theme.colors.text_dark};

  margin-left: ${({ isItem }) => {
    if (isItem === "template") return 1;
    if (isItem === "step") return 0.7;
    if (isItem === "task") return 0.4;
  }}rem;
`;

export const NewItem = styled.div<IProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  background: unset;

  font-size: 1.6rem;

  border: 0.25px solid ${({ theme }) => theme.colors.text_light};

  width: ${({ isItem }) => {
    if (isItem === "template") return 1.9;
    if (isItem === "step") return 1.7;
    if (isItem === "task") return 1.5;
  }}rem;
  height: ${({ isItem }) => {
    if (isItem === "template") return 1.9;
    if (isItem === "step") return 1.7;
    if (isItem === "task") return 1.5;
  }}rem;

  margin-left: 1rem;
`;

export const Infos = styled.div`
  width: 40%;

  margin-left: 3rem;
  margin-top: 3rem;

`;

export const Name = styled.div`
  font-size: 1.3rem;
`;

export const Type = styled.div`
  margin-top: 0.2rem;

  font-size: 1.02rem;
`;

export const TypeOps = styled.div`
  margin-top: 0.3rem;

  font-size: 1rem;
`;

export const Describe = styled.div`
  margin-top: 0.5rem;

  width: 70%;
`;

export const ButtonAdd = styled(Button)`
  font-size: 0.7rem;

  height: 25px;
  min-width: 6rem;

  margin-left: 0.6rem;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonOrder = styled(Button)`
  font-size: 0.7rem;
  height: 25px;
  margin-left: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
