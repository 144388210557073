import { api } from "../services/api";
import { useLogged } from "./userLogin";

import {
  createContext,
  useContext,
  useState,
  ReactNode,
} from "react";

interface IProps {
  children: ReactNode;
}

export interface IPhasesProject {
  title: string,
  quantity: string,
};

export interface IPercentageToCompleteProjects {
  projectName: string;
  percentageToComplete: number
};

export interface IUserProjectAndTasksNotComplete {
  userName: string;
  projectsNotComplete: number;
  tasksNotComplete: number;
};

interface IDashboardContext {
  phasesProject: IPhasesProject[];
  projectsNumber: number;
  finishedProjects: number;
  fetchPhasesProject: () => Promise<void>;
  dataPercentageToCompleteProjects: IPercentageToCompleteProjects[];
  percentageToCompleteProjects: () => Promise<void>;
  dataUserProjectAndTasksNotComplete: IUserProjectAndTasksNotComplete[];
  userProjectAndTasksNotComplete: () => Promise<void>;
  loading: boolean;
  error: boolean;
}

export const DashboardContext = createContext<IDashboardContext>({} as IDashboardContext);

export const DashboardStorage = ({ children }: IProps) => {
  const [phasesProject, setPhasesProject] = useState<IPhasesProject[]>([]);
  const [dataPercentageToCompleteProjects, setDataPercentageToCompleteProjects] = useState<IPercentageToCompleteProjects[]>([]);
  const [dataUserProjectAndTasksNotComplete, setDataUserProjectAndTasksNotComplete] = useState<IUserProjectAndTasksNotComplete[]>([]);
  const [projectsNumber, setProjectsNumber] = useState<number>(0);
  const [finishedProjects, setFinishedProjects] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const { token } = useLogged();


  async function fetchPhasesProject() {
    setLoading(true);
    setError(null);

    let url = `dashboard/projects`;

    api
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
      )
      .then((response) => {
        setPhasesProject(response.data.data.phases);
        setProjectsNumber(response.data.data.projectsNumber);
        setFinishedProjects(response.data.data.finishedProjects);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function percentageToCompleteProjects() {
    setLoading(true);
    setError(null);

    let url = `dashboard/percentage-to-complete-projects`;

    api
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
      )
      .then((response) => {
        setDataPercentageToCompleteProjects(response.data.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function userProjectAndTasksNotComplete() {
    setLoading(true);
    setError(null);

    let url = `dashboard/user-project-not-complete`;

    api
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
      )
      .then((response) => {
        setDataUserProjectAndTasksNotComplete(response.data.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <DashboardContext.Provider
      value={{
        phasesProject,
        projectsNumber,
        finishedProjects,
        fetchPhasesProject,
        dataPercentageToCompleteProjects,
        percentageToCompleteProjects,
        userProjectAndTasksNotComplete,
        dataUserProjectAndTasksNotComplete,
        loading,
        error,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export function useDashboard() {
  return useContext(DashboardContext);
}
