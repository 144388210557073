import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 1rem;

  #email-input {
    font-size: 1rem;
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.colors.text_light};
    border-radius: 1.5rem;
    width: 100%;
    padding: 0.8rem 1.2rem;
    box-sizing: border-box;
    transition: 0.1s;
  }
`;
