import { Button, Form, Input, message, Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { useRoles } from '../../hooks/useRoles';
import { NewModal } from '../NewModal';
import { useNavigate } from 'react-router-dom';

export function NewGroupsModal() {
  let navigate = useNavigate()

  const handleCancel = () => {
      handleModalVisible(false);
  };

  const {
      permissions,
      createRole,
      fetchRoles,
      isNewModalVisible,
      handleModalVisible,
  } = useRoles();

  const onFinish = async (form: any) => {
    const status = await createRole({
      description: form.description,
      name: form.name,
      permissionsIds: form.permissions,
    });

    if (status)
      message.success("Aprovado");
    else
      message.warning("Falha");

    handleModalVisible(false);
    fetchRoles(1, 'asc', 'name');
    navigate('/groups/1');
  }

  return (
    <NewModal
      buttonModalText="Abra o modal"
      visible={isNewModalVisible}
      handleCancel={handleCancel}
      handleOk={() => null}
      title="Novo grupo"
    >
      <Form
        onFinish={onFinish}
      >
        <FormItem
          name="name"
          label="Nome"
          style={{
            marginBottom: "25px",
          }}
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório!",
            },
          ]}
        >
          <Input maxLength={50} placeholder="Nome do grupo!" />
        </FormItem>

        <FormItem
            name="description"
            label="Descrição"
            style={{
              marginBottom: "25px",
            }}
            rules={[
              {
                required: true,
                message: "Este campo é obrigatório!",
              },
            ]}
        >
          <Input maxLength={50} placeholder="Descrição do grupo!" />
        </FormItem>

        <FormItem
          name="permissions"
          label="Permissões"
          style={{
            marginBottom: "25px",
          }}
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório!",
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="Selecione as permissões!"
            style={{ width: "100%" }}
          >
            {permissions.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.alias}
              </Select.Option>
            ))}
          </Select>
        </FormItem>

        <FormItem style={{ paddingLeft: "63%" }}>
          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
          <Button onClick={() => handleModalVisible(false)}>Cancelar</Button>
        </FormItem>
      </Form>
    </NewModal>
  );
}
