import React, { InputHTMLAttributes } from "react";
import { Control, Controller } from "react-hook-form";
import { Input } from "../Input";
import { Container } from "./styles";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  control: Control;
  type: string;
  label: string;
  name: string;
  error: string;
}

export function InputForm({
  control,
  type,
  label,
  name,
  error,
  ...rest
}: IProps) {
  return (
    <Container>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <Input
            name={name}
            type={type}
            error={error}
            value={value}
            onChange={onChange}
            {...rest}
          />
        )}
        name={name}
      />
    </Container>
  );
}
