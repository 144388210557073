import { Avatar, AvatarProps } from "antd";
import { any } from "prop-types";
import { useLogged } from "../../hooks/userLogin";

interface IUserAvatarProps {
  userName?: string
}

function UserAvatar({ userName }: IUserAvatarProps) {
  const userLogged = useLogged();
  const iniciais = (userName || userLogged.user.name)
    .trim()
    .split(" ")
    .reduce((acc, part) => `${acc}${part.substring(0, 1)}`, "")
    .toUpperCase()
    .substring(0, 2);

  return <Avatar>{iniciais}</Avatar>;
}

export default UserAvatar;
