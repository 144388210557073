import { ReactNode, createContext, useContext, useState } from "react";
import { useLogged } from "./userLogin";
import { api } from "../services/api";
import { message } from "antd";

interface IProps {
  children: ReactNode;
}

export interface IProjects {
  project_id: string;
  current_phase: string;
  project_image: string,
  project_title: string,
  user_responsable: string,
  result_finish_date: string,
}

export interface IProjectsPhases {
  phase_title: string;
  projects: IProjects[];
};

interface IGeneralContext {
  projectsPhases: IProjectsPhases[];
  fetchProjectPhases: (user_id?: string) => Promise<void>;
  loading: boolean;
  error: any;
  fetchUsers: () => Promise<void>;
  users: Array<any>;
}

export const GeneralContext = createContext<IGeneralContext>({} as IGeneralContext);

export const GeneralStorage = ({ children }: IProps) => {
  const [users, setUsers] = useState<Array<any>>([]);
  const [projectsPhases, setProjectsPhases] = useState<IProjectsPhases[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const { token } = useLogged();

  async function fetchProjectPhases(user_id?: string) {
    setLoading(true);
    setError(null);

    let url = `/general/phases`;

    if (user_id !== undefined) {
      url += `?userId=${user_id}`
    }

    api
      .get(url,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((response) => {
        setProjectsPhases(response.data.data.phases);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function fetchUsers() {
    try {
      const { data } = await api.get(`/users`);
      setUsers(data.data.users);
    } catch (error: any) {
      message.error('Erro ao buscar usuários');
    }
  }

  return (
    <GeneralContext.Provider
      value={{
        projectsPhases,
        fetchProjectPhases,
        loading,
        error,
        fetchUsers,
        users,
      }}
    >
      {children}
    </GeneralContext.Provider>
  )
};

export function useGeneral() {
  return useContext(GeneralContext);
}