import styled from "styled-components";

export const Kanban = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 170px);
  max-height: calc(100vh - 170px);
  overflow-x: auto;
`;

export const Phase = styled.div`
  min-width: 300px;
  padding: 0 10px;
  margin-right: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;

  .title-container {
    display: flex;
    justify-content: space-between;

    p.title {
      font-weight: 500;
      padding: 10px;
    }

    button {
      width: 22px;
      margin-top: 10px;
    }
  }
`;

export const ProjectContainer = styled.div`
  height: calc(100% - 60px);
  overflow-y: scroll;
`;

export const Project = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100px;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border: 1px solid #fff;
  cursor: pointer;

  &:hover {
    border-color: #f7cd11;
  }

  .title {
    font-weight: 500;
  }

  .project_info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    label {
      font-size: 0.6rem;
    }
  }

  .project_plan {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    label {
      font-size: 0.6rem;
    }
  }
`;