import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
} from "react";
import { api } from "../services/api";

interface IProps {
  children: ReactNode;
}

interface IUser {
  id: string;
  name: string;
  email: string;
  photo: string;
  company: {
    id: string;
    name: string;
    logo?: string;
    primary_color: string;
    secondary_color: string;
  };
  role_id: string,
  // role: {
  //   name: "default";
  // };
}

interface IUserContext {
  userLogin: (email: string, password: string) => Promise<void>;
  userLogout: () => Promise<void>;
  token: string | null;
  login: boolean;
  loading: boolean;
  error: string | null;
  user: IUser;
  getUser: (token :string) => Promise<void>
}

export const UserContext = createContext<IUserContext>({} as IUserContext);

export const UserStorage = ({ children }: IProps) => {
  const [user, setUser] = useState({} as IUser);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [login, setLogin] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);

  async function userLogin(email: string, password: string) {
    try {
      setLoading(true);
      setError(null);

      api
        .post("/users/login", {
          email,
          password,
        })
        .then((response) => {
          let { token } = response.data.data;
          localStorage.setItem("token", token);
          setToken(token);
          setLogin(true);
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setError("Login ou senha incorretos");
          }
        });
    } catch (error: any) {
      setError(error);
      setLogin(false);
      setToken(null);
    } finally {
      setLoading(false);
    }
  }

  async function userLogout() {
    setToken(null);
    setError(null);
    setLoading(false);
    setLogin(false);
    setUser({} as IUser);

    localStorage.removeItem("token");
  }

  async function getUser(token: string) {
    const response = await api.get("/users/info", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setUser(response.data.data);
    setLogin(true);
  }

  React.useEffect(() => {
    async function autoLogin() {
      const token = window.localStorage.getItem("token");
      if (token) {
        try {
          setError(null);
          setLoading(true);
          setToken(token);
          await getUser(token);
        } catch (err) {
          userLogout();
        } finally {
          setLoading(false);
        }
      } else {
        setLogin(false);
      }
    }
    autoLogin();
  }, [token]);

  return (
    <UserContext.Provider
      value={{ loading, login, error, userLogin, userLogout, token, user, getUser  }}
    >
      {children}
    </UserContext.Provider>
  );
};

export function useLogged() {
  const context = useContext(UserContext);

  return context;
}
