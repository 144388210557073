import { useEffect, useState } from "react";
import moment from "moment";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Pagination,
  Popconfirm,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import {
  BorderOuterOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import * as _ from 'lodash';
import { useTemplates } from "../../hooks/useTemplates";
import { useProjects } from "../../hooks/useProjects";
import { useRoles } from "../../hooks/useRoles";
import { UploadImages } from '../UploadImages';

import { NewModal } from "../NewModal";
import Meta from "antd/lib/card/Meta";

interface IEditProjects {
  title: string;
  description: string;
  id: string;
  date_init: any;
  template_id: string;
  status_id: number;
  image: string;
  templates?: Array<any>;
  onFinish?: any;
  onCancel?: any;
}

const status = [
  { id: 1, title: 'Em andamento' },
  { id: 2, title: 'On hold' },
  { id: 3, title: 'Finalizado' },
];

const FormEdit = ({ title, template_id, description, date_init, status_id, image, templates, onFinish, onCancel, }: IEditProjects) => {
  const [baseImage, setBaseImage] = useState('');

  const handleFinish = (form: any) => {
    const newForm = {...form}
    if (baseImage) {
      newForm.image = baseImage;
    }
    onFinish(newForm);
  }

  return (
    <Form
      onFinish={handleFinish}
      initialValues={{ title, template_id, description, date_init, status_id }}
    >
      <Form.Item
        name="title"
        label="Titulo"
        style={{
          marginBottom: "25px",
        }}
        rules={[
          {
            required: true,
            message: "Este campo é obrigatório!",
          },
        ]}
      >
        <Input
          maxLength={50}
          placeholder="Titulo do Projeto!"
        />
      </Form.Item>

      <Form.Item
        name="description"
        label="Descrição"
        style={{
          marginBottom: "25px",
        }}
        rules={[
          {
            required: true,
            message: "Este campo é obrigatório!",
          },
        ]}
      >
        <Input maxLength={50} placeholder="Descrição" />
      </Form.Item>

      <Form.Item
        name="date_init"
        label="Data de início"
        style={{
          marginBottom: "25px",
        }}
        rules={[
          {
            required: true,
            message: "Este campo é obrigatório!",
          },
        ]}
      >
        <Input type="date" placeholder="Data de início!" disabled />
      </Form.Item>

      <FormItem
        name="template_id"
        label="Template"
        style={{
          marginBottom: "25px",
        }}
        rules={[
          {
            required: true,
            message: "Este campo é obrigatório!",
          },
        ]}
      >
        <Select
          placeholder="Selecione o template!"
          style={{ width: "100%" }}
          disabled
        >
          {!!templates && templates.map((item) => (
            <Select.Option key={item.id} value={item.id}>
              {item.title}
            </Select.Option>
          ))}
        </Select>
      </FormItem>

      <FormItem
        name="status_id"
        label="Status"
        style={{
          marginBottom: "25px",
        }}
        rules={[
          {
            required: true,
            message: "Este campo é obrigatório!",
          },
        ]}
      >
        <Select
          placeholder="Selecione o status!"
          style={{ width: "100%" }}
        >
          {!!status && status.map((item) => (
            <Select.Option key={item.id} value={item.id}>
              {item.title}
            </Select.Option>
          ))}
        </Select>
      </FormItem>

      <FormItem>
        <UploadImages
          baseImage={baseImage}
          setBaseImage={setBaseImage}
          onRemove={() => setBaseImage('')}
        />
      </FormItem>

      <FormItem style={{ paddingLeft: "63%" }}>
        <Button type="primary" htmlType="submit">
          Salvar
        </Button>
        <Button onClick={onCancel}>Cancelar</Button>
      </FormItem>
    </Form>
  )
};

export interface IPhase {
  title: string;
  description: string;
  id: string;
}

export interface IProjectsRow {
  title: string;
  description: string;
  id: string;
  date_init: any;
  template_id: string;
  current_phase: string;
  plan_finish_date: string;
  result_finish_date: string;
}

export function ProjectsTable() {
  const { permissions } = useRoles();
  const {
    fetchProjects,
    loading,
    projects,
    totalPages,
    deleteProjects,
    updateProjects,
    handleModalVisible,
    total,
    currentPhases
  } = useProjects();

  const { templates } = useTemplates();

  const newProject =
    permissions.filter((item) => item.name === "newUser").length > 0;

  const currentRoute = useParams();
  const navigate = useNavigate();
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [page, setPage] = useState<number>(Number(currentRoute.page));
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState<string>("title");
  const [formDefault, setFormDefault] = useState<IEditProjects>({} as IEditProjects);
  const [search, setSearch] = useState<string>("");
  const [displayType, setdisplayType] = useState<'table' | 'tile'>("table");
  const [filterPhase, setFilterPhase] = useState<string[]>([]);

  const fetchProjectDebounce = _.debounce(() => {
    return fetchProjects(page, order, orderBy, search, filterPhase);
  }, 500);

  useEffect(() => {
    if (page && order && orderBy) {
      fetchProjectDebounce();
    }
  }, [page, order, orderBy, filterPhase]);

  const handleCancelEdit = () => {
    setVisibleEdit(false);
  };

  const handleSaveEdit = async (id: string, form: any) => {
    setVisibleEdit(false);
    const status: any = await updateProjects(id, form);

    if (status?.status === 406) {
      return message.warning("Existem usuários nesse grupo!");
    }

    if (status?.status !== 200) {
      return message.error("Falha!");
    }

    fetchProjects(page, order, orderBy);
    message.success("Editado!");
  }

  const handleShowProject = (record: any) => {
    navigate(`/project-details/${record.id}`);
  }

  const confirmExclusion = async (record: any) => {
    const status: any = await deleteProjects(record.id);

    if(status?.response?.status == 500 || status?.response?.status == 406){
      message.warning('Falha')
    } else {
      message.success("Excluido")
    }
  }

  const orderbyCresDesc = (pagination: any, filters: any, sorter: any, extra: any) => {
    if (sorter.field) {
      setOrderBy(sorter.field);
    }

    if (sorter.order) {
      setOrder(sorter.order === "descend" ? "desc" : "asc");
    }
  }

  const clickableField = (value: string, record: IProjectsRow) => (
      <a
      style={{ cursor: "pointer", color: "black", backgroundColor: "red", width: "100%", height: "100%" }}
      onClick={() => handleShowProject(record)}
    >
      {value}
    </a>
  );

  const columns: any = [
    {
      title: "Nome",
      dataIndex: "title",
      key: "title",
      sorter: (a: IProjectsRow, b: IProjectsRow) => a.title.localeCompare(b.title),
    },

    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
      filterSearch: true,
      width: "30%",
      sorter: (a: IProjectsRow, b: IProjectsRow) => a.description.localeCompare(b.description),
      onFilter: (value: any, record: any) => record.role.startsWith(value),
    },

    {
      title: "Data de início",
      key: "date_init",
      dataIndex:  "date_init",
      filterSearch: true,
      width: "30%",
      sorter: (a: IProjectsRow, b: IProjectsRow) => a.date_init.localeCompare(b.date_init),
      onFilter: (value: any, record: any) => record.role.startsWith(value),
    },

    {
      title: "Previsão de conclusão",
      key: "plan_finish_date",
      dataIndex:  "plan_finish_date",
      filterSearch: true,
      width: "30%",
      sorter: (a: IProjectsRow, b: IProjectsRow) => a.result_finish_date.localeCompare(b.result_finish_date),
      onFilter: (value: any, record: any) => record.role.startsWith(value),
      render: (_: string, record: IProjectsRow) => moment(record.result_finish_date.split('T')[0]).format('DD/MM/YYYY'),
    },

    {
      title: "Etapa atual",
      key: "current_phase",
      dataIndex:  "current_phase",
      filterSearch: false,
      width: "30%",
      sorter: (a: IProjectsRow, b: IProjectsRow) => a.current_phase.localeCompare(b.current_phase),
      onFilter: (value: any, record: any) => record.role.startsWith(value),
    },

    {
      title: "Status",
      key: "status_id",
      dataIndex:  "status_id",
      filterSearch: false,
      width: "30%",
      render: (value: number) => status.find(st => st.id == value)?.title,
    },

    {
      title: "Ações",
      key: "action",
      render: (_: string, record: IProjectsRow) => (
        <Space size="middle" onClick={(e) => e.stopPropagation()}>
          <a
            style={{
              color: "#1F6ED4",
            }}
            onClick={() => {
              handleShowProject(record)
            }}
          >
            Detalhes
          </a>

          <a
            style={{
              color: "#F7CD11",
            }}
            onClick={() => {
              const project = projects.find(item => item.id === record.id);
              if (project) {
                setFormDefault({
                  id: project?.id,
                  title: project?.title,
                  date_init: moment(project?.date_init.split('T')[0]).format('YYYY-MM-DD'),
                  description: project?.description,
                  template_id: project?.template_id,
                  status_id: project?.status_id,
                  image: project?.image,
                });
                setVisibleEdit(true);
              }
            }}
          >
            Editar
          </a>

            <Popconfirm
              title="Você tem certeza?"
              onConfirm={() => confirmExclusion(record)}
              okText="Sim"
              cancelText="Não"
            >
              <a
                style={{
                  color: "#ff0000",
                }}
              >
                Deletar
              </a>
            </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row style={{ marginBottom: '8px' }}>
        <Col span={24} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '90%' }}
            placeholder="Filtrar por etapa atual"
            onChange={setFilterPhase}
          >
            {
              currentPhases
                .sort((a, b) => a < b ? -1 : 1)
                .map(phase => <Select.Option key={phase}>{phase}</Select.Option>)
            }
          </Select>

          <Radio.Group value={displayType} onChange={(event) => setdisplayType(event.target.value)}>
            <Radio.Button value="table">
              <UnorderedListOutlined />
            </Radio.Button>
            <Radio.Button value="tile">
              <BorderOuterOutlined />
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>

      {
        displayType == 'table' && (
          <Table
            showSorterTooltip={{ title: "Clique para ordenar" }}
            dataSource={
              projects.map((e) => {
                return {
                  ...e,
                  date_init: moment(e.date_init, "YYYY-MM-DD").format("DD/MM/YYYY")
                }
              })
            }
            columns={columns}
            rowKey={(item) => item.id}
            loading={loading}
            pagination={{
              position: ["bottomRight"],
              pageSize: 7,
              current: Number(page),
              total: total,
              onChange: (pageAux) => {
                navigate(`/projects/${pageAux}`);
                setPage(pageAux);
              },
            }}
            onChange={orderbyCresDesc}
            onRow={(record, recordIndex) => ({
              onClick: (event) => {
                handleShowProject(record);
              }
            })}
          />
        )
      }

      {
        displayType == 'tile' && (
          <>
            <Row style={{ justifyContent: 'flex-start', }}>
              {
                projects.map((project) => (
                  <Card
                    key={project.id}
                    hoverable
                    style={{ width: '24%', margin: 2 }}
                    cover={
                      project.image
                      ? <img alt={`image of ${project.title}`} src={project.image} style={{ backgroundColor: '#d9d9d9', width: '100%', height: 180, objectFit: 'cover' }} />
                      : <div style={{ backgroundColor: '#d9d9d9', width: '100%', height: 180 }}></div>
                    }
                    onChange={(event) => null}
                  >
                    <Meta title={project.title} description={project.description}/>
                    <Row style={{ flexDirection: 'column', marginBottom: 20, marginTop: 10,  padding: 0 }}>
                      <Typography.Text style={{ fontSize: 12 }}>
                        Data de início: {moment(project.date_init, "YYYY-MM-DD").format("DD/MM/YYYY")}
                      </Typography.Text>
                      <Typography.Text style={{ fontSize: 12 }}>
                        Previsão de conclusão: {moment(project.result_finish_date.split('T')[0]).format('DD/MM/YYYY')}
                      </Typography.Text>
                      <Typography.Text style={{ fontSize: 12 }}>
                        Etapa atual: {project.current_phase}
                      </Typography.Text>
                    </Row>
                    <Row style={{ justifyContent: 'center', position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
                      <Tooltip title="Detalhes">
                        <Button
                          onClick={() => handleShowProject(project)}
                          style={{ flex: 1, borderWidth: 0 }}
                        >
                          <EyeOutlined key="show" />
                        </Button>
                      </Tooltip>

                      <Tooltip title="Editar">
                        <Button
                          onClick={() => {
                            setFormDefault({
                              id: project?.id,
                              date_init: project?.date_init,
                              description: project?.description,
                              template_id: project?.template_id,
                              status_id: project?.status_id,
                              image: project?.image,
                              title: project?.title,
                            });
                            setVisibleEdit(true);
                          }}
                          style={{ flex: 1, borderWidth: 0 }}
                        >
                          <EditOutlined key="edit" />
                        </Button>
                      </Tooltip>

                      <Popconfirm
                        title="Você tem certeza?"
                        onConfirm={() => confirmExclusion(project)}
                        okText="Sim"
                        cancelText="Não"
                      >
                        <Tooltip title="Deletar">
                          <Button
                            style={{ flex: 1, borderWidth: 0 }}
                          >
                            <DeleteOutlined key="delete" />
                          </Button>
                        </Tooltip>
                      </Popconfirm>
                    </Row>
                  </Card>
                ))
              }
            </Row>
            <Row style={{ justifyContent: 'flex-end', marginTop: 10 }}>
              <Pagination
                defaultCurrent={page}
                onChange={(pageAux) => setPage(pageAux)}
                total={total}
              />
            </Row>
          </>
        )
      }

      {
        visibleEdit && (
          <NewModal
            buttonModalText={"Editar"}
            visible={visibleEdit}
            handleCancel={handleCancelEdit}
            handleOk={() => null}
            title="Editar projeto"
          >
            <FormEdit
              id={formDefault.id}
              title={formDefault.title}
              description={formDefault.description}
              date_init={formDefault.date_init}
              template_id={formDefault.template_id}
              status_id={formDefault.status_id}
              image={formDefault.image}
              templates={templates}
              onFinish={(form: any) => handleSaveEdit(formDefault.id, form)}
              onCancel={() => setVisibleEdit(false)}
            />
          </NewModal>
        )
      }
    </>
  );
}
