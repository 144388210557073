
export default {
  colors: {
    primary: "#c30830",
    primary_strong: "#c30830",

    border: "rgba(0, 66, 193, 0.1)",

    secondary: "#2c87ff",
    secondary_light: "rgba(44, 135, 255, 0.3)",

    attention: "#d13f3f",
    attention_light: "rgba(209, 63, 63, 0.5)",

    shape: "#ffffff",
    shape_light: "#f5f5f5",

    fields: "#f9f9f9",
    fields_strong: "#f0f1f5",

    success: "#12a451",
    success_light: "rgba(18, 164, 84, 0.5)",

    warning: "#F0DB18",

    text_dark: "#000000",
    text_light: "rgba(0, 0, 0, 0.3)",
    title: "#363F5F",
    text: "#969CB2",
    background: "#F0F2F5",
  },

  fonts: {
    regular: "Poppins_400Regular",
    medium: "Poppins_500Medium",
    bold: "Poppins_700Bold",
  },
};
