import styled from "styled-components";
import { Content as AntContent } from "antd/lib/layout/layout";

export const Container = styled.div`
  background-color: #f1f2f4;
  height: 100%;

  .children-container {
    min-height: calc(100vh - 80px);
    padding: 0;

    .site-layout-background {
      padding: 24px;
      margin: 0;
      min-height: 280px;
    }
  }
`;

export const Content = styled(AntContent)`
  padding: 10px;
`;

export const Dashboard = styled.div`
  background-color: ${({ theme }) => theme.colors.shape};
  border-radius: 5px;
  padding: 10px;
  overflow-x: auto;
  /* height: 100%; */
`;
