import styled from "styled-components";
import { Button as ButtonAntd } from "antd";

export const Container = styled(ButtonAntd)`
  width: 100%;
  height: 3rem;

  padding: 14px 0;
  border-radius: 1.5rem;

  color: #c30830;

  border: 1px solid #c30830;
  background-color: ${({ theme }) => theme.colors.shape};
`;
