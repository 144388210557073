import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  height: 5rem;
  padding: 0 3rem;
  box-shadow: 0 4px 2px -2px rgba(255, 255, 255, 0.5);
  align-items: center;
  background-color: ${({ theme }) => theme.colors.shape};
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);

  @media (max-width: 800px) {
    padding: 0 1rem;
  }
`;

export const Options = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Option = styled.a`
  margin-right: 15px;
  color: ${({ theme }) => theme.colors.text_dark};
  font-size: 1rem;
`;
