import { Button, Form, Input, message, Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { NewModal } from '../NewModal';

import { useNavigate } from 'react-router-dom';
import { useProjects } from '../../hooks/useProjects';
import { useTemplates } from '../../hooks/useTemplates';
import { UploadImages } from '../UploadImages';
import { useState } from 'react';

export function NewProjectModal() {
  let navigate = useNavigate();

  const {
    handleModalVisible,
    isNewModalVisible,
    createProjects,
    fetchProjects,
    loading
  } = useProjects();

  const { templates } = useTemplates();

  const handleOk = () => { };
  const [baseImage, setBaseImage] = useState("");

  const onFinish = async (form: any) => {
    form.image = baseImage;

    const result = await createProjects(form);

    if (result.status != 200) {
      return message.warning("Falha");
    }

    handleModalVisible(false);
    message.success("Aprovado")
    fetchProjects(1, 'asc', 'title');
    navigate('/projects/1');
  };

  return (
      <NewModal
        buttonModalText={"Abra o modal"}
        visible={isNewModalVisible}
        handleCancel={() => handleModalVisible(false)}
        handleOk={handleOk}
        title="Novo Projeto"
      >
        <Form
          onFinish={onFinish}
          initialValues={{
            title: '',
            description: '',
            date_init: '',
            template_id: '',
            image: '',
          }}
        >
          <Form.Item
            name="title"
            label="Titulo"
            style={{
              marginBottom: "25px",
            }}
            rules={[
              {
                required: true,
                message: "Este campo é obrigatório!",
              },
            ]}
          >
            <Input maxLength={50} placeholder="Titulo do projeto" />
          </Form.Item>

          <Form.Item
            name="description"
            label="Descrição"
            style={{
              marginBottom: "25px",
            }}
            rules={[
              {
                required: true,
                message: "Este campo é obrigatório!",
              },
            ]}
          >
            <Input maxLength={50} placeholder="descrição" />
          </Form.Item>

          <Form.Item
            name="date_init"
            label="Data de Início"
            style={{
              marginBottom: "25px",
            }}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Este campo é obrigatório!",
              },
              {
                type: "string",
                message: "Data de Início",
              },
            ]}
          >
            <Input type="date" placeholder="Data de Início" />
          </Form.Item>

          <FormItem
            name="template_id"
            label="Template"
            style={{
              marginBottom: "25px",
            }}
            rules={[
              {
                required: true,
                message: "Este campo é obrigatório!",
              },
            ]}
          >
            <Select
              placeholder="Selecione o templete!"
              style={{ width: "100%" }}
            >
              {templates.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </FormItem>

          <FormItem>
            <UploadImages
              baseImage={baseImage}
              setBaseImage={setBaseImage}
              disabled={loading}
            />
          </FormItem>

          <FormItem style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Salvar
            </Button>
            <Button onClick={() => handleModalVisible(false)} disabled={loading}>Cancelar</Button>
          </FormItem>
        </Form>
      </NewModal>
    );

}
