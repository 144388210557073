import { useLogged } from "../../hooks/userLogin";
import { Container, Option, Options } from "./styles";
import { useUser } from '../../hooks/useUsers';
import { useNavigate } from "react-router-dom";
import { BellOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";

export function Header() {
  const { userLogout } = useLogged();
  let navigate = useNavigate();

  function handleLogout() {
    userLogout()
    navigate('/')
  }
  const { handleEditModalVisible } = useUser();

  return (
    <Container>
      <Options>
        <Option onClick={() => navigate('/notifications/1')}>
          <BellOutlined /> Notificações
        </Option>
        <Option onClick={() => handleEditModalVisible(true)}>
          <UserOutlined /> Meu perfil
        </Option>
        <Option onClick={handleLogout}>
          <LogoutOutlined /> Sair
        </Option>
      </Options>
    </Container>
  );
}
