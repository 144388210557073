import { Button, Form, Input, message, Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem'; 
import { NewModal } from '../NewModal';
import { useUser } from "../../hooks/useUsers"; 
 
import { Option } from 'antd/lib/mentions';
import { useNavigate } from 'react-router-dom';


export function NewUserModal() {

    let navigate = useNavigate()
    const {
        users,
        loading,
        fetchUsers,
        total,
        roles,
        updateUser,
        deleteUser,
        createUser,
        handleModalVisible,
        isNewModalVisible,
    } = useUser();

    const handleCancel = () => {
        handleModalVisible(false);
    };

    const handleOk = () => { };

    return (


        <NewModal
            buttonModalText={"Abra o modal"}
            visible={isNewModalVisible}
            handleCancel={handleCancel}
            handleOk={handleOk}
            title="Novo Usuário"
        >

            <Form
                onFinish={async (form) => {
                    const status = await createUser(form);

                    if (status) message.success("Aprovado");
                    else message.warning("Falha");

                    handleModalVisible(false);
                    fetchUsers(1, 'asc', 'name');
                    navigate('/users/1')
                }}
            >
                <Form.Item
                    name="name"
                    label="Nome"
                    style={{
                        marginBottom: "25px",
                    }}
                    rules={[
                        {
                            required: true,
                            message: "Este campo é obrigatório!",
                        },
                    ]}
                >
                    <Input maxLength={50} placeholder="Nome do usuário!" />
                </Form.Item>
                <Form.Item
                    name="email"
                    label="E-mail"
                    style={{
                        marginBottom: "25px",
                    }}
                    rules={[
                        {
                            required: true,
                            message: "Este campo é obrigatório!",
                        },
                        {
                            type: "email",
                            message: "Esse campo é um email",
                        },
                    ]}
                >
                    <Input maxLength={50} placeholder="Senha" />
                </Form.Item>
                <Form.Item
                    name="password"
                    label="Senha"
                    style={{
                        marginBottom: "25px",
                    }}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: "Este campo é obrigatório!",
                        },
                    ]}
                >
                    <Input type="password" maxLength={50} placeholder="Senha" />
                </Form.Item>
                <Form.Item
                    name="passwordConfirmation"
                    label="Confirmação"
                    dependencies={["password"]}
                    hasFeedback
                    style={{
                        marginBottom: "25px",
                    }}
                    rules={[
                        {
                            required: true,
                            message: "Este campo é obrigatório!",
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue("password") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error("As duas senhas precisam ser iguais!")
                                );
                            },
                        }),
                    ]}
                >
                    <Input
                        type="password"
                        maxLength={50}
                        placeholder="Confirme sua senha!"
                    />
                </Form.Item>
                <FormItem
                    name="role_id"
                    label="Grupo"
                    style={{
                        marginBottom: "25px",
                    }}
                    rules={[
                        {
                            required: true,
                            message: "Este campo é obrigatório!",
                        },
                    ]}
                >
                    <Select
                        placeholder="Selecione o grupo!"
                        onChange={() => { }}
                        style={{ width: "100%" }}
                    >
                        {roles.map((item) => (
                            <Option key={item.id}>{item.name}</Option>
                        ))}
                    </Select>
                </FormItem>
                <FormItem style={{ paddingLeft: "63%" }}>
                    <Button type="primary" htmlType="submit">
                        Salvar
                    </Button>
                    <Button onClick={() => handleModalVisible(false)}>Cancelar</Button>
                </FormItem>
            </Form>
        </NewModal>

    );

}