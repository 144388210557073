import { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as _ from 'lodash';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { useProjects } from "../../hooks/useProjects";

import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  Table,
  message,
  Input,
} from "antd";

import {
  CalendarOutlined, DeleteOutlined, FileExcelOutlined, SearchOutlined,
} from "@ant-design/icons";
import { api } from "../../services/api";

export function Reports() {
  const [isModalLoading, setModalLoading] = useState(false);
  const currentRoute = useParams();
  const [page, setPage] = useState<number>(Number(currentRoute.page));
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState<string>("title");
  const [search, setSearch] = useState<string>("");
  const [searchUsersProjects, setSearchUsersProjects] = useState<string>("");
  const [projectStatusSearch, setProjectStatusSearch] = useState<Array<string | number>>([1]);
  const [taskStatusSearch, setTaskStatusSearch] = useState<Number | any>();
  const [users, setUsers] = useState<Array<any>>();
  const [valueDatePrevistInit, setValueDatePrevistInit] = useState<Moment | null>(null);
  const [valueDatePrevistFinal, setValueDatePrevistFinal] = useState<Moment | null>(null);
  const [valueDateRealizeInit, setValueDateRealizeInit] = useState<Moment | null>(null);
  const [valueDateRealizeFinal, setValueDateRealizeFinal] = useState<Moment | null>(null);
  const [termSearch, setTermSearch] = useState<string | null>(null)
  const projectStatuses = [
    { id: 1, title: 'Em andamento' },
    { id: 2, title: 'On hold' },
    { id: 3, title: 'Finalizado' },
  ];
  const taskStatuses = [
    { id: 0, title: 'Não iniciada' },
    { id: 1, title: 'Em Progresso' },
    { id: 2, title: 'Finalizada' }
  ];

  const { userId } = useParams();

  const fetchUsers = async () => {
    try {
      const { data } = await api.get(`/users`);
      setUsers(data.data.users);
    } catch (error: any) {
      message.error('Erro ao buscar usuários');
    }
  }

  function alterValueDates(e: any, valueToAlter: string) {
    switch (valueToAlter) {
      case "valueDatePrevistInit":
        if (!!e) {
          setValueDatePrevistInit(moment(e))

        } else {
          setValueDatePrevistInit(null)
        }
        break;

      case "valueDatePrevistFinal":
        if (!!e) {
          setValueDatePrevistFinal(moment(e))

        } else {
          setValueDatePrevistFinal(null)
        }
        break;

      case "valueDateRealizeInit":
        if (!!e) {
          setValueDateRealizeInit(moment(e))

        } else {
          setValueDateRealizeInit(null)
        }
        break;

      case "valueDateRealizeFinal":
        if (!!e) {
          setValueDateRealizeFinal(moment(e))

        } else {
          setValueDateRealizeFinal(null)
        }
        break;
    }
  };

  function handleSearchTerms(e: any) {
    const text = e.target.value;
    setTermSearch(text);
  };

  const navigate = useNavigate();

  const {
    loading,
    projectsReport,
    total,
    allProjects,
    fetchProjectsReports,
    handleReportExportArqv,
    fetchAllProjects,
  } = useProjects();

  useEffect(() => {
    if (userId !== undefined) {
      handleSearch();
    } else {
      fetchAllProjects();
    }

    fetchUsers();
  }, []);

  const fetchProjectDebounce = _.debounce(() => {
    const dates: any = {
      plan_init_date: valueDatePrevistInit?.format('YYYY-MM-DD'),
      plan_finish_date: valueDatePrevistFinal?.format('YYYY-MM-DD'),
      result_init_date: valueDateRealizeInit?.format('YYYY-MM-DD'),
      result_finish_date: valueDateRealizeFinal?.format('YYYY-MM-DD'),
    }

    let usersProject: string = searchUsersProjects;
    if (userId !== undefined) { // válido para a página Minhas Tarefas
      usersProject = userId;
    }

    return fetchProjectsReports(
      page,
      order,
      orderBy,
      search,
      usersProject,
      dates,
      termSearch,
      projectStatusSearch,
      taskStatusSearch
    );
  }, 500);

  const orderbyCresDesc = (pagination: any, filters: any, sorter: any, extra: any) => {
    if (sorter.field) {
      setOrderBy(sorter.field);
    }

    if (sorter.order) {
      setOrder(sorter.order === "descend" ? "desc" : "asc");
    }
  }

  async function handleSearch() {
    fetchProjectDebounce()
  }

  function handleCleanFilter() {
    window.location.reload();
  };

  async function handleExportInterM() {
    const dates: any = {
      plan_init_date: valueDatePrevistInit?.format('YYYY-MM-DD'),
      plan_finish_date: valueDatePrevistFinal?.format('YYYY-MM-DD'),
      result_init_date: valueDateRealizeInit?.format('YYYY-MM-DD'),
      result_finish_date: valueDateRealizeFinal?.format('YYYY-MM-DD'),
    };

    handleReportExportArqv(
      page,
      order,
      orderBy,
      search,
      searchUsersProjects,
      dates,
      termSearch,
      projectStatusSearch,
      taskStatusSearch
    );
  }

  useEffect(() => {
    if (page && order && orderBy) {
      fetchProjectDebounce();
    }
  }, [page, order, orderBy]);

  const columns: any = [
    {
      title: "Nome do Projeto",
      dataIndex: "projects_title",
      key: "projects_title",
      onFilter: (value: any, record: any) => record.role.startsWith(value),
      filterSearch: true,
      width: "12%",
    },
    {
      title: "Status do Projeto",
      dataIndex: "projects_status_id",
      key: "projects_status_id",
      onFilter: (value: any, record: any) => record.role.startsWith(value),
      filterSearch: true,
      width: "12%",
      render: (value: number) => projectStatuses.find(st => st.id == value)?.title
    },
    {
      title: "Nome do Usuário Atribuído",
      dataIndex: "us_tk_name",
      key: "us_name",
      onFilter: (value: any, record: any) => record.role.startsWith(value),
      filterSearch: true,
      width: "12%",
    },
    {
      title: "Etapa",
      dataIndex: "projects_current_phase",
      sorter: (a: any, b: any) => a.projects_current_phase.localeCompare(b.projects_current_phase),
      key: "projects_current_phase",
      onFilter: (value: any, record: any) => record.role.startsWith(value),
      filterSearch: true,
      width: "10%",
    },
    {
      title: "Tarefa",
      dataIndex: "tks_title",
      sorter: (a: any, b: any) => a.tks_title.localeCompare(b.tks_title),
      key: "tks_title",
      onFilter: (value: any, record: any) => record.role.startsWith(value),
      filterSearch: true,
      width: "10%",
    },
    {
      title: "Data prevista Início: ",
      dataIndex: "tks_plan_init_date",
      key: "tks_plan_init_date",
      filterSearch: false,
      width: "15%",
      sorter: (a: any, b: any) => a.tks_plan_init_date.localeCompare(b.tks_plan_init_date),
      onFilter: (value: any, record: any) => record.role.startsWith(value),
      render: (_: string, record: any) => moment(record.tks_plan_init_date.split('T')[0]).format('DD/MM/YYYY')
    },
    {
      title: "Data prevista de Conclusão: ",
      dataIndex: "tks_plan_finish_date",
      key: "tks_plan_finish_date",
      filterSearch: false,
      width: "15%",
      sorter: (a: any, b: any) => a.tks_plan_finish_date.localeCompare(b.tks_plan_finish_date),
      onFilter: (value: any, record: any) => record.role.startsWith(value),
      render: (_: string, record: any) => moment(record.tks_plan_finish_date.split('T')[0]).format('DD/MM/YYYY')
    },
    {
      title: "Data realizada de Início: ",
      dataIndex: "tks_result_init_date",
      key: "tks_result_init_date",
      filterSearch: false,
      width: "15%",
      onFilter: (value: any, record: any) => record.role.startsWith(value),
      sorter: (a: any, b: any) => a.tks_result_init_date.localeCompare(b.tks_result_init_date),
      render: (_: string, record: any) => moment(record.tks_result_init_date.split('T')[0]).format('DD/MM/YYYY')
    },
    {
      title: "Data realizada de Conclusão: ",
      dataIndex: "tks_result_finish_date",
      key: "tks_result_finish_date",
      filterSearch: false,
      width: "15%",
      onFilter: (value: any, record: any) => record.role.startsWith(value),
      sorter: (a: any, b: any) => a.tks_result_finish_date.localeCompare(b.tks_result_finish_date),
      render: (_: string, record: any) => moment(record.tks_result_finish_date.split('T')[0]).format('DD/MM/YYYY')
    },
    {
      title: "Status: ",
      dataIndex: "tks_status_id",//taskStatuses.find(st => st.id === currentTask.status_id)?.title,
      key: "tks_status_id",
      filterSearch: false,
      width: "15%",
      onFilter: (value: any, record: any) => record.role.startsWith(value),
      sorter: (a: any, b: any) => a.tks_result_finish_date.localeCompare(b.tks_result_finish_date),
      render: (_: string, record: any) => (taskStatuses.find(st => st.id === record.tks_status_id)?.title)
    }
  ];

  return (
    <>
      <Row style={{ marginBottom: '8px', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Col span={16}>
          <div style={{ flexDirection: 'row' }}>
            <Row>
              <Col span={12}>
                <label> Buscar por Projetos (nome):</label>
                <Row>
                  <Select
                    style={{ flex: 1 }}
                    placeholder="Filtrar por projeto"
                    onChange={e => setSearch(e)}
                    mode="multiple"
                    allowClear
                  >
                    {
                      allProjects
                        .map(phase => <Select.Option key={phase.title}>{phase.title}</Select.Option>)
                    }
                  </Select>
                </Row>
              </Col>

              {!userId &&
                <Col span={12}>
                  <label> Buscar por Usuários (nome):</label>
                  <Row>
                    <Select
                      style={{ flex: 1 }}
                      placeholder="Filtrar por usuários"
                      onChange={e => setSearchUsersProjects(e)}
                      mode="multiple"
                      allowClear
                    >
                      {
                        users?.map(user => (
                          <Select.Option value={user.id}>{user.name}</Select.Option>
                        ))
                      }
                    </Select>
                  </Row>
                </Col>
              }

              <Col span={24}>
                <label> Buscar por Termo:</label>
                <Row>
                  <Input
                    placeholder="Filtrar por termo"
                    onChange={e => handleSearchTerms(e)}
                  />
                </Row>
              </Col>

              <Col span={12}>
                <label> Buscar por Status: (projeto) </label>
                <Row>
                  <Select
                    style={{ flex: 1 }}
                    placeholder="Filtrar por status"
                    onChange={e => setProjectStatusSearch(e)}
                    value={projectStatusSearch}
                    allowClear
                    mode="multiple"
                  >
                    {
                      projectStatuses?.map(e => (
                        <Select.Option value={e.id}>{e.title}</Select.Option>
                      ))
                    }
                  </Select>
                </Row>
              </Col>

              <Col span={12}>
                <label> Buscar por Status: (tarefa) </label>
                <Row>
                  <Select
                    style={{ flex: 1 }}
                    placeholder="Filtrar por status"
                    onChange={e => setTaskStatusSearch(e)}
                    allowClear
                    mode="multiple"
                  >
                    {
                      taskStatuses?.map(e => (
                        <Select.Option value={e.id}>{e.title}</Select.Option>
                      ))
                    }
                  </Select>
                </Row>
              </Col>
            </Row>
          </div>

          <Row>
            <Col span={6}>
              <label><CalendarOutlined /> Data prevista início (de): </label>
              <Row>
                <DatePicker
                  value={valueDatePrevistInit}
                  format='DD/MM/YYYY'
                  disabled={isModalLoading}
                  onChange={(e) => { alterValueDates(e, 'valueDatePrevistInit') }}
                  placeholder="Selecione uma data"
                  allowClear={true}
                  locale={locale}
                  style={{ flex: 1 }}
                />
              </Row>
            </Col>

            <Col span={6}>
              <label><CalendarOutlined /> Data prevista início (até): </label>
              <Row>
                <DatePicker
                  value={valueDatePrevistFinal}
                  format='DD/MM/YYYY'
                  disabled={isModalLoading}
                  onChange={(e) => { alterValueDates(e, 'valueDatePrevistFinal') }}
                  placeholder="Selecione uma data"
                  allowClear={true}
                  locale={locale}
                  style={{ flex: 1 }}
                />
              </Row>
            </Col>

            <Col span={6}>
              <label><CalendarOutlined /> Data prevista término (de): </label>
              <Row>
                <DatePicker
                  value={valueDateRealizeInit}
                  format='DD/MM/YYYY'
                  disabled={isModalLoading}
                  onChange={(e) => { alterValueDates(e, 'valueDateRealizeInit') }}
                  placeholder="Selecione uma data"
                  allowClear={true}
                  locale={locale}
                  style={{ flex: 1 }}
                />
              </Row>
            </Col>

            <Col span={6}>
              <label><CalendarOutlined /> Data prevista término (até): </label>
              <Row>
                <DatePicker
                  value={valueDateRealizeFinal}
                  format='DD/MM/YYYY'
                  disabled={isModalLoading}
                  onChange={(e) => { alterValueDates(e, 'valueDateRealizeFinal') }}
                  placeholder="Selecione uma data"
                  allowClear={true}
                  locale={locale}
                  style={{ flex: 1 }}
                />
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center', height: '100%' }}>
            <Button
              type="primary"
              onClick={handleSearch}
              style={{ marginTop: 2, width: 200 }}
            >
              <SearchOutlined />
              Buscar
            </Button>

            <Button
              onClick={handleExportInterM}
              style={{ marginTop: 2, width: 200 }}
            >
              <FileExcelOutlined />
              Exportar XLS
            </Button>

            <Button
              onClick={handleCleanFilter}
              style={{ marginTop: 2, width: 200 }}
            >
              <DeleteOutlined />
              Limpar filtros
            </Button>

          </div>
        </Col>
      </Row>

      <Table
        showSorterTooltip={{ title: "Clique para ordenar" }}
        dataSource={
          projectsReport.map((e) => {
            return {
              ...e,
              date_init: moment(e.date_init, "YYYY-MM-DD").format("DD/MM/YYYY")
            }
          })
        }

        columns={columns}
        rowKey={(item) => item.id}
        onChange={orderbyCresDesc}
        loading={loading}
        pagination={
          {
            showSizeChanger: false,
            current: Number(page),
            total: total,
            onChange: (pageAux) => {
              navigate(`/reports/${pageAux}`);
              setPage(pageAux);
            },
          }
        } />
    </>
  )
};
