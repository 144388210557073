import { useEffect, useState } from "react";
import { useTemplates } from "../../hooks/useTemplates";
import {
  SearchOutlined,
} from "@ant-design/icons";

import {
  Button,
  Input,
  message,
  Popconfirm,
  Space,
  Table,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";

interface ITemplatesRow {
  title: string;
  type: string;
  description: string;
  id: string;
  civil_type: string,
  ops_type: string,
}

 export function TemplatesTable() {
  const {
    error,
    fetchTemplates,
    loading,
    templates,
    totalPages,
    cloneTemplates,
    deleteTemplates,
    total
  } = useTemplates()

  const currentRoute = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(Number(currentRoute.page));
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState<string>("title");
  const [search, setSearch] = useState<string>("");
  const data = templates.map((item) => {
    return {
      title: item.title,
      type: item.ops_type,
      description: item.description,
    };
  });

  useEffect(() => {
    if (search != "") {
      fetchTemplates(totalPages, order, orderBy, search);
    } else {
      fetchTemplates(page, order, orderBy);
    }
  }, [page]);

  function orderbyCresDesc(pagination: any, filters: any, sorter: any, extra: any) {
    if (sorter.field) setOrderBy(sorter.field);

    if (sorter.order === "descend") {
      if (search != "") {
        fetchTemplates(page, "desc", sorter.field, search);
        setOrder("desc");
        setOrderBy(sorter.field ? sorter.field : "title");
      } else {
        fetchTemplates(page, "desc", sorter.field);
        setOrder("desc");
        setOrderBy(sorter.field ? sorter.field : "title");
      }
    } else {
      if (search != "") {
        fetchTemplates(page, "asc", sorter.field, search);
        setOrder("asc");
        setOrderBy(sorter.field ? sorter.field : "title");
      } else {
        fetchTemplates(page, "asc", sorter.field);
        setOrder("asc");
        setOrderBy(sorter.field ? sorter.field : "title");
      }
    }
  }

  const confirmClonar = async (record: any) => {
    const status: any = await cloneTemplates(record.id);
    if(status?.response?.status == 500 || status?.response?.status == 406){
      message.warning('Falha')
    } else {
      message.success("Clonado!")
    }
  }

  const confirmExclusion = async (record: any) => {
    const index = data.indexOf(record);
    const status: any = await deleteTemplates(record.id);
    if(status?.response?.status == 500 || status?.response?.status == 406){
      message.warning('Falha')
    } else {
      message.success("Excluido")
    }
  }

  const dataEditTemplates = async (record :any) => {
    const defaultValue = templates.filter (
      (item) => item.id === record.id
    )[0];

    let auxFormDefault = {
      id: defaultValue.id,
      title: defaultValue.title,
      civil_type: defaultValue.civil_type,
      description: defaultValue.description,
      ops_type: defaultValue.ops_type,
      steps: defaultValue.steps
    }

    navigate('/edit/template', { state: auxFormDefault });
  }

  const columns: any = [
    {
      title: "Nome",
      dataIndex: "title",
      key: "title",
      sorter: (a: ITemplatesRow, b: ITemplatesRow) => a.title.localeCompare(b.title),
      filterDropdown: () => (
        <div>
          <Input
            maxLength={50}
            placeholder="Buscar nome"
            onChange={({ target }) => setSearch(target.value)}
            style={{
              width: "9rem",
            }}
          />
          <Button
            type="primary"
            onClick={() => fetchTemplates(page, order, orderBy, search)}
          >
            Buscar
          </Button>
        </div>
      ),
      filterIcon: (
        <SearchOutlined
          style={{
            fontSize: 17,
          }}
        />
      ),
    },
    {
      title: "Descrição",
      dataIndex: "description",
      sorter: (a: ITemplatesRow, b: ITemplatesRow) => a.description.localeCompare(b.description),
      key: "description",
      onFilter: (value: any, record: any) => record.role.startsWith(value),
      filterSearch: true,
      width: "30%",
    },
    {
      title: "Tipo Civil",
      dataIndex: "civil_type",
      sorter: (a: ITemplatesRow, b: ITemplatesRow) => a.civil_type.localeCompare(b.civil_type),
      key: "civil_type",
      onFilter: (value: any, record: any) => record.role.startsWith(value),
      filterSearch: true,
      width: "30%",
    },
    {
      title: "Tipo de Operação",
      dataIndex: "ops_type",
      sorter: (a: ITemplatesRow, b: ITemplatesRow) => a.ops_type.localeCompare(b.ops_type),
      key: "ops_type",
      onFilter: (value: any, record: any) => record.role.startsWith(value),
      filterSearch: true,
      width: "40%",
    },
    {
      title: "Ações",
      key: "action",
      render: (text: string, record: ITemplatesRow) => (
        <Space size="middle">
          <Popconfirm
            title="Deseja realmente clonar?"
            onConfirm={(e) => {
              e?.stopPropagation();
              confirmClonar(record);
            }}
            onCancel={(e) => e?.stopPropagation()}
            okText="Sim"
            cancelText="Não"
          >
            <a
              style={{
                color: "#1F6ED4",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              Clonar
            </a>
          </Popconfirm>

          <a
            style={{
              color: "#F7CD11",
            }}
            onClick={() => { dataEditTemplates(record)
              }}
          >
            Editar
          </a>

          <Popconfirm
            title="Você tem certeza?"
            onConfirm={(e) => {
              e?.stopPropagation();
              confirmExclusion(record);
            }}
            onCancel={(e) => e?.stopPropagation()}
            okText="Sim"
            cancelText="Não"
          >
            <a
              style={{
                color: "#ff0000",
              }}
              onClick={(e) => e.stopPropagation()}
            >

              Deletar
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table
        showSorterTooltip={{ title: "Clique para ordenar" }}
        dataSource={templates}
        columns={columns}
        onRow={(record, recordIndex) => ({
          onClick: (event) => {
            dataEditTemplates(record);
          }
        })}
        rowKey={(item) => item.id}
        onChange={orderbyCresDesc}
        loading={loading}
        pagination={{
          position: ["bottomRight"],
          pageSize: 7,
          current: Number(page),
          total: total,
          onChange: (pageAux) => {
            navigate(`/templates/${pageAux}`);
            setPage(pageAux);
          },

        }}
      />
    </div>
  );
}
