
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

function UploadImages ({ baseImage, setBaseImage, onRemove, disabled }:any) {
  const uploadImage = async (e :any)  => {
    const file = e.fileList[0].originFileObj
    const base64 :any = await convertBase64(file);

    setBaseImage(base64);
  };

  const convertBase64 = (file :any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const props = {
      name: 'file',
      maxCount: 1,
      headers: {
          authorization: 'authorization-text',
      },
  }

  return(
    <Upload {...props}
      onChange={(e) => uploadImage(e)}
      onRemove={onRemove}
      accept="image/*"
      beforeUpload={() => false}
    >
      <Button
        icon={<UploadOutlined />}
        disabled={disabled}
      >
        Imagem do Projeto
      </Button>
    </Upload>
  )
}

export { UploadImages }
