import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Container, Form, LoginBox } from "./styles";
import { InputForm } from "../../components/Form/InputForm";
import { Button } from "../../components/Form/Button";
import { useLogged } from "../../hooks/userLogin";

const schema = Yup.object().shape({
  email: Yup.string()
    .email("Esse campo deve ser um email")
    .required("Esse campo é obrigatório"),
  password: Yup.string().required("Esse campo é obrigatório"),
});

export function Login() {
  const { userLogin, error } = useLogged();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (form) => {
    await userLogin(form.email, form.password);

    if (error) {
      setError("password", {
        type: "manual",
        message: "Usuário ou senha inválidos",
      });
    }
  });

  return (
    <Container>
      <LoginBox>
        <Form>
          <h1 className="title">Entrar</h1>
          <InputForm
            name="email"
            label="E-mail"
            control={control}
            placeholder="E-mail"
            type="email"
            error={errors.email && errors.email.message}
            className="fields"
          />
          <InputForm
            name="password"
            label="Senha"
            control={control}
            placeholder="Senha"
            type="password"
            error={errors.password && errors.password.message}
            className="fields"
          />
          <Button
            label="Entrar"
            onClick={onSubmit}
            style={{
              marginTop: "12%",
            }}
          />
        </Form>
      </LoginBox>
    </Container>
  );
}
