import { useEffect, useState } from "react";
import {
  FundProjectionScreenOutlined,
  IdcardOutlined,
  PlusCircleOutlined,
  ProjectOutlined,
  UnorderedListOutlined,
  FileTextOutlined,
  RiseOutlined,
  UserOutlined,
  DashboardOutlined,
  FolderOpenOutlined
} from "@ant-design/icons";
import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { useNavigate } from "react-router-dom";

import { useRoles } from "../../hooks/useRoles";
import { useUser } from "../../hooks/useUsers";
import { useProjects } from "../../hooks/useProjects";
import { useLogged } from "../../hooks/userLogin";

import {
  Sidebar,
  LogoContainer,
} from './styles'

export default function LateralBar() {
  const [collapsed, setCollapsed] = useState(false);
  const [logo, setLogo] = useState<string | undefined>('');
  const navigate = useNavigate();

  const roles = useRoles();
  const users = useUser();
  const projects = useProjects();
  const auth = useLogged();

  useEffect(() => {
    setLogo(auth.user.company.logo);
  }, [])

  const listUser =
    roles.permissions.filter((item) => item.name === "editUser").length > 0;
  const newUser =
    roles.permissions.filter((item) => item.name === "editUser").length > 0;
  const listRoles =
    roles.permissions.filter((item) => item.name === "listRole").length > 0;
  const newRole =
    roles.permissions.filter((item) => item.name === "editRole").length > 0;
  const listTemplates =
    roles.permissions.filter((item) => item.name === "listTemplates").length > 0;
  const editTemplates =
    roles.permissions.filter((item) => item.name === "editTemplates").length > 0;
  const listProjects =
    roles.permissions.filter((item) => item.name === "listProjects").length > 0;
  const newProjects =
    roles.permissions.filter((item) => item.name === "editProject").length > 0;
  const listReports =
    roles.permissions.filter((item) => item.name === 'listReports').length > 0;

  return (
    <Sidebar
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      className="sidebar"
    >
      <LogoContainer collapsed={collapsed}>
        <img
          src={logo || ''}
          alt='Logo da empresa'
          className="logo"
          onClick={() => navigate("/")}
        />
      </LogoContainer>
      <Menu
        theme="light"
        mode="inline"
        className="menus"
      >
        {listUser && (
          <SubMenu key="users" icon={<UserOutlined />} title="Usuários">
            {listUser && (
              <Menu.Item key="users-list" onClick={() => navigate("/users/1")}>
                <UnorderedListOutlined />
                <span className="menu-item-title">Listagem</span>
              </Menu.Item>
            )}
            {newUser && (
              <Menu.Item key="users-new" onClick={() => users.handleModalVisible(true)}>
                <PlusCircleOutlined />
                <span className="menu-item-title">Novo usuário</span>
              </Menu.Item>
            )}
          </SubMenu>
        )}

        {listRoles && (
          <SubMenu key="groups" icon={<IdcardOutlined />} title="Grupos">
            {listRoles && (
              <Menu.Item key="groups-list" onClick={() => navigate("/groups/1")}>
                <UnorderedListOutlined />
                <span className="menu-item-title">Listagem</span>
              </Menu.Item>
            )}
            {newRole && (
              <Menu.Item key="groups-new" onClick={() => roles.handleModalVisible(true)}>
                <PlusCircleOutlined />
                <span className="menu-item-title">Novo grupo</span>
              </Menu.Item>
            )}
          </SubMenu>
        )}

        {listTemplates && (
          <SubMenu key="templates" icon={<FundProjectionScreenOutlined />} title="Templates" >
            {listTemplates && (
              <Menu.Item key="templates-list" onClick={() => navigate("/templates/1")}>
                <UnorderedListOutlined />
                <span className="menu-item-title">Listagem</span>
              </Menu.Item>
            )}
            {editTemplates && (
              <Menu.Item key="templates-new" onClick={() => navigate("/create/template")}>
                <PlusCircleOutlined />
                <span className="menu-item-title">Novo template</span>
              </Menu.Item>
            )}
          </SubMenu>
        )}

        {listProjects && (
          <SubMenu key="projects" icon={<ProjectOutlined />} title="Projetos" >
            {listProjects && (
              <Menu.Item key="projects-list" onClick={() => navigate("/projects/1")}>
                <UnorderedListOutlined />
                <span className="menu-item-title">Listagem</span>
              </Menu.Item>
            )}
            {newProjects && (
              <Menu.Item key="projects-new" onClick={() => projects.handleModalVisible(true)}>
                <PlusCircleOutlined />
                <span className="menu-item-title">Novo Projeto</span>
              </Menu.Item>
            )}
          </SubMenu>
        )}

           <SubMenu  key="reports"  icon={<FileTextOutlined />} title="Relatórios" >
              <Menu.Item key="reports" onClick={() => navigate("/reports/1")}>
                <UnorderedListOutlined />
                <span className="menu-item-title">Tabela</span>
              </Menu.Item>

              <Menu.Item key="reports/highcharts" onClick={() => navigate("/reports/highcharts")}>
                <RiseOutlined />
                <span className="menu-item-title">Gráfico</span>
              </Menu.Item>
          </SubMenu>

          <Menu.Item key="dashboard" onClick={() => navigate("/dashboard")} >
              <DashboardOutlined />
              <span className="menu-item-title">Dashboard</span>
          </Menu.Item>

          <Menu.Item key="general" onClick={() => navigate("/general")} >
            <ProjectOutlined />
              <span className="menu-item-title">Visão Geral</span>
          </Menu.Item>

          <Menu.Item key="my-tasks" onClick={() => navigate("/reports/1/" + auth.user.id)} >
            <FolderOpenOutlined />
              <span className="menu-item-title">Minhas tarefas</span>
          </Menu.Item>

      </Menu>
    </Sidebar>
  );
}
