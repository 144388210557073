import styled from 'styled-components';

export const DashboardWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
`;

export const Square = styled.div`
  width: 180px;
  height: 82px;
  background-color: #3498db;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-radius: 20px;
  flex-wrap: wrap;
`;

export const SpanTitle = styled.span`
  width: 100%;
  justify-content: center;
  display: flex;
  padding: 5px 0 0 5px;
  position: relative;
  top: -25px;
`;

export const SpanQuantity = styled.span`
  font-size: 27px;
  font-weight: 600;
  position: absolute;
  margin-top: 17px;
`;

export const ChartsContainer = styled.div`
  width: 100%;
  margin-top: 45px;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-evenly;
  }

  @media (max-width: 768px) {
    display: block;
    div {
      overflow-x: auto !important;
    }
  }
`;
